import { getBlogDetails, updateBlog, uploadBlogs } from '../../../helper/api'

import React, { useEffect, useRef, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'

const VALID_TYPES = ['image/png', 'image/jpeg', 'image/jpg']

function Blogs() {
  let { id } = useParams()
  let history = useHistory()
  const editorRef = useRef()
  const [content, setContent] = useState('')
  const [signature, setsignature] = useState('')
  const [title, setTitle] = useState('')
  const [bannerImage, setbannerImage] = useState('')
  const [status, setStatus] = useState({ type: '', text: '' })
  const [fileErrors, setfileError] = useState('')
  const [formSubmited, setFormSubmited] = useState(false)
  const [blogData, setBlogData] = useState([])
  const [state, setState] = useState('')
  const [tempImage, setTempImage] = useState()
  const [createdAt, setDate] = useState('')

  useEffect(() => {
    if (id == 'add') {
      setState('add')
    } else {
      getDetails(id)
    }
  }, [id])

  const getDetails = async (id) => {
    let blogData = await getBlogDetails({ id: id })

    if (blogData?.data?._id) {
      setBlogData(blogData?.data)
    } else {
      history.push('/dashboard/blogs')
    }
  }

  useEffect(() => {
    if (blogData?._id) {
      setTitle(blogData?.title)
      setbannerImage(blogData?.banner)
      setDate(blogData?.createdAt ? blogData.createdAt : '')
      setsignature(blogData?.signature)
      editorRef.current.setContents(blogData?.description)
      setContent(blogData?.description)
    }
  }, [blogData])

  const submitBlog = async (e) => {
    e.preventDefault()
    if (!bannerImage) {
      setStatus({ type: 'error', text: 'Please upload a banner!' })
      return
    }

    let data = {
      title: title,
      description: content,
      signature: signature,
      createdAt: createdAt,
    }

    if (state == 'add') {
      data.file = bannerImage
    } else {
      data.file = blogData.banner == bannerImage ? null : bannerImage
      data.id = blogData?._id
    }

    try {
      if (fileErrors === '' && !formSubmited) {
        let response

        if (state == 'add') {
          response = await uploadBlogs(data)
        } else {
          response = await updateBlog(data)
        }
        if (response?.data?.error) {
          setStatus({ type: 'error', text: response?.data?.message })
        } else {
          setStatus({ type: 'success', text: response?.data?.message })
          setFormSubmited(true)
          history.push('/dashboard/blogs')
        }
      }
    } catch (error) {
      console.log(error)
      setStatus({ type: 'error', text: error.message })
      throw error
    }
  }

  const handleChangeBanner = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setfileError('Please select a file')
      return
    }

    if (e.target.files[0].size / 1024 >= 5 * 1024) {
      setfileError('File may not be greater than 5 MB')
      return
    }

    if (!VALID_TYPES.includes(e.target.files[0].type)) {
      setfileError('File format should be jpeg, jpg, png')
      return
    }

    if (e.target.files) {
      var file = e.target.files[0]
      var reader = new FileReader()
      var url = reader.readAsDataURL(file)
      reader.onloadend = await function (e) {
        setTempImage([reader.result])
        // formik.setFieldValue("photo", [reader.result][0]);
      }

      setbannerImage(file)
    }
  }

  const handleChange = (content) => {
    setContent(content)
  }

  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor
  }

  return (
    <>
      <Form.Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Add Blogs</Card.Title>
            </Card.Header>
            <Card.Body>
              <Alert show={status.type === 'error'} variant="danger">
                {status.text}
              </Alert>
              <Alert show={formSubmited} variant="success">
                <Alert.Heading>Form Submited Sucessfully !</Alert.Heading>
              </Alert>
              <Form onSubmit={submitBlog}>
                <Form.Row>
                  {!bannerImage && (
                    <div className="mb-3 col-md-6">
                      <Form.File id="formcheck-api-regular">
                        <Form.File.Label>Upload Banner</Form.File.Label>
                        <Form.File.Input onChange={handleChangeBanner} required />
                      </Form.File>
                      {fileErrors && (
                        <div className="text-left">
                          <label className="text-center text-danger">{fileErrors}</label>
                        </div>
                      )}
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid File.
                      </Form.Control.Feedback>
                    </div>
                  )}
                  {(bannerImage || tempImage) && (
                    <div className="mb-3 col-md-6">
                      <img src={tempImage ? tempImage : bannerImage} width="200" alt="something" />
                      <span className="customSettings">
                        <em
                          className="fa fa-trash "
                          onClick={() => {
                            bannerImage && setbannerImage('')
                            tempImage && setTempImage('')
                          }}
                        ></em>
                      </span>
                    </div>
                  )}
                </Form.Row>
                <Form.Row>
                  <Col className="px-1" md={6}>
                    <Form.Group>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        placeholder="Add Title"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-1" md={6}>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>signature</Form.Label>
                      <Form.Control
                        placeholder="Add Signature"
                        as="textarea"
                        required
                        rows={2}
                        value={signature}
                        onChange={(e) => setsignature(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="px-1" md={6}>
                    <Form.Group>
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        placeholder="Add Date"
                        type="datetime-local"
                        value={createdAt}
                        onChange={(e) => setDate(e.target.value)}
                        required
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-1" md={6}>
                    <Form.Group>
                      <Form.Label>Blog</Form.Label>
                      <SunEditor
                        name="content"
                        defaultValue={content}
                        onChange={handleChange}
                        getSunEditorInstance={getSunEditorInstance}
                        setOptions={{
                          height: 200,
                          buttonList: [
                            [
                              'formatBlock',
                              'font',
                              'fontSize',
                              'fontColor',
                              'align',
                              'paragraphStyle',
                              'blockquote',
                            ],
                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                            ['removeFormat'],
                            ['outdent', 'indent'],
                            ['table', 'list'],
                            ['link', 'image', 'video'],
                          ],
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Button type="submit">{state == 'add' ? 'Submit' : 'Update'}</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Form.Row>
    </>
  )
}

export default Blogs
