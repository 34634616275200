import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Redirect, BrowserRouter as Router, Switch } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.css'

import StepForm from './views/creationForm'
import Dashboard from './views/dashboard'
import Login from './views/login'

import Layout from './components/common/layout'

import { PrivateRoute, ProvideAuth, PublicRoute } from './helper/auth'
import './stylesheets/index.scss'

function App() {
  return (
    <ProvideAuth>
      <Container fluid={true}>
        <Router>
          <Row>
            <Col>
              <Layout />
            </Col>
          </Row>
          <Row>
            <Switch>
              <PublicRoute path="/login" children={<Login />} />
              <PrivateRoute path="/dashboard" children={<Dashboard />} />
              <PrivateRoute path="/createVendor" children={<StepForm />} />
              <Redirect from="/*" to="/login" />
            </Switch>
          </Row>
        </Router>
      </Container>
    </ProvideAuth>
  )
}

export default App
