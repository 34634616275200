import { getAllAmenities } from '../../../../helper/api'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Switch from '@material-ui/core/Switch'

import React, { useEffect, useState, useTransition } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Dropdown, DropdownButton, Nav, Navbar } from 'react-bootstrap'

import { spaceDetails } from '../../../../components/form/formElements'

const workSpaceTypes = [
  'Coworking Spaces',
  'Dedicated Workstation',
  'Private Rooms',
  'Board Rooms',
  'Training Rooms',
  'Events Space',
  'Daily Passes',
  'Virtual Offices',
  'Hot Desk',
  'Flexi Desk',
  'Conference Room',
  'Private Cabins',
  'Meeting Room',
  'Custom Built',
  'Private Office',
]

const timeSlots = [
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
  '00:00',
]

const AddWorkSpace = ({ handleAdd, msg }) => {
  const [isPending, startTransition] = useTransition()

  const [allAmenties, setAmenties] = useState([])
  const [status, setStatus] = useState({
    name: '',
    space_code: '',
    address: '',
    city: '',
    state: '',
    area: '',
    description: '',
    amenities: [],
    spaceTypes: [],
    day_pass: '',
    open_desk: '',
  })

  const [weeklyHours, setWeeklyHours] = useState([
    {
      id: 1,
      day: 'Monday',
      open24hrs: false,
      isOpen: true,
      openTime: '00:00',
      closeTime: '00:00',
    },
    {
      id: 2,
      day: 'Tuesday',
      open24hrs: false,
      isOpen: true,
      openTime: '00:00',
      closeTime: '00:00',
    },
    {
      id: 3,
      day: 'Wednesday',
      open24hrs: false,
      isOpen: true,
      openTime: '00:00',
      closeTime: '00:00',
    },
    {
      id: 4,
      day: 'Thursday',
      open24hrs: false,
      isOpen: true,
      openTime: '00:00',
      closeTime: '00:00',
    },
    {
      id: 5,
      day: 'Friday',
      open24hrs: false,
      isOpen: true,
      openTime: '00:00',
      closeTime: '00:00',
    },
    {
      id: 6,
      day: 'Saturday',
      open24hrs: false,
      isOpen: true,
      openTime: '00:00',
      closeTime: '00:00',
    },
    {
      id: 7,
      day: 'Sunday',
      open24hrs: false,
      isOpen: false,
      openTime: '00:00',
      closeTime: '00:00',
    },
  ])
  const [pricings, setPricings] = useState([{ id: 1, spaceType: '', cost: '', timePeriod: '' }])

  const handePricingChange = (keyName, pricingId, e = '') => {
    if (keyName == 'timePeriod' || keyName == 'spaceType' || keyName == 'cost') {
      let pricingsData = pricings.map((pricing) => {
        if (pricing.id == pricingId) {
          pricing[keyName] = e.target.value
        }
        return pricing
      })
      setPricings(pricingsData)
    }
  }

  const getAmenties = async () => {
    let amenties = await getAllAmenities()
    setAmenties(amenties.allAmenities)
  }

  const changeTimeSlot = (keyName, weeklyHrsId, time = '00:00') => {
    if (keyName == 'openTime' || keyName == 'closeTime') {
      let weeklyHoursData = weeklyHours.map((wHrs) => {
        if (wHrs.id == weeklyHrsId) {
          wHrs[keyName] = time
        }
        return wHrs
      })
      setWeeklyHours(weeklyHoursData)
    }
    if (keyName == 'isOpen' || keyName == 'open24hrs') {
      let weeklyHoursData = weeklyHours.map((wHrs) => {
        if (wHrs.id == weeklyHrsId) {
          wHrs[keyName] = !wHrs[keyName]
        }
        return wHrs
      })
      setWeeklyHours(weeklyHoursData)
    }
  }

  const handleCheckChange = (e) => {
    let newStatus = { ...status }
    if (newStatus[e.target.name].includes(e.target.value)) {
      let index = newStatus[e.target.name].indexOf(e.target.value)
      newStatus[e.target.name].splice(index, 1)
    } else {
      newStatus[e.target.name].push(e.target.value)
    }
    setStatus(newStatus)
  }

  const addNewPricing = (e) => {
    setPricings(
      pricings.concat({
        id: pricings.length + 1,
        spaceType: '',
        cost: '',
        timePeriod: '',
      })
    )
  }
  const handleChange = (e) => {
    let { name, value } = e.target
    let newStatus = { ...status }
    if (name === 'space_name') newStatus['name'] = value
    else newStatus[name] = value
    startTransition(() => setStatus(newStatus))
  }

  const handleSubmit = () => {
    console.log(status)
    let workSpaceDetails = status
    workSpaceDetails['pricings'] = pricings.filter(
      (pricing) => pricing.spaceType && pricing.cost && pricing.timePeriod
    )
    workSpaceDetails['weekly_hours'] = weeklyHours
    handleAdd(workSpaceDetails)
  }

  useEffect(() => {
    getAmenties()
  }, [])

  return (
    <Container fluid={true}>
      <h1 className="text-center">WorkSpace Details</h1>
      <Form>
        {spaceDetails.map((data, inx) => {
          let { label, type, name, placeholder, required, options } = data
          return (
            <Row key={name + inx} className="form-group">
              <label>{label}</label>
              <input
                className="form-control"
                type={type}
                name={name}
                onBlur={handleChange}
                // value={status[name]}
                placeholder={placeholder}
                required={required}
              />
            </Row>
          )
        })}
      </Form>
      <Form>
        <Row className="form-group">
          <label>Amenities</label>
        </Row>
        <Row className="form-group">
          {allAmenties &&
            allAmenties.length > 0 &&
            allAmenties.map((data, inx) => {
              return (
                <Col key={data.name + inx} className="form-check col-sm-6 col-md-4 col-lg-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={data.name}
                    name={'amenities'}
                    onChange={handleCheckChange}
                  ></input>
                  <label className="form-check-label">{data.name}</label>
                </Col>
              )
            })}
        </Row>
      </Form>
      <Form>
        <Row className="form-group">
          <label>Pricing</label>
        </Row>
        {pricings.map((pricing, index) => {
          return (
            <Row className="form-group">
              <Col>
                <input
                  className="form-control"
                  type="text"
                  name="priceSpaceType"
                  onChange={(e) => handePricingChange('spaceType', pricing.id, e)}
                  value={pricing.spaceType}
                  placeholder="Space Type"
                />
              </Col>
              <Col>
                <input
                  className="form-control"
                  type="text"
                  name="priceSpaceCost"
                  onChange={(e) => handePricingChange('cost', pricing.id, e)}
                  value={pricing.cost}
                  placeholder="Cost"
                  required={true}
                />
              </Col>
              per
              <Col>
                <select
                  className="form-control"
                  value={pricing.timePeriod}
                  onChange={(e) => handePricingChange('timePeriod', pricing.id, e)}
                >
                  <option>Time Period</option>
                  <option>Day</option>
                  <option>Month</option>
                  <option>Year</option>
                </select>
              </Col>
              <Col className="text center">
                {pricings.length == index + 1 && (
                  <i className="fa fa-plus fa-3" aria-hidden="true" onClick={addNewPricing}></i>
                )}
              </Col>
            </Row>
          )
        })}
      </Form>
      <Form>
        <Row className="form-group">
          <label>Types Of Space</label>
        </Row>
        <Row className="form-group">
          {workSpaceTypes &&
            workSpaceTypes.length > 0 &&
            workSpaceTypes.map((data, inx) => {
              return (
                <Col key={data + inx} className="form-check col-sm-6 col-md-4 col-lg-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={data}
                    name={'spaceTypes'}
                    onChange={handleCheckChange}
                  ></input>
                  <label className="form-check-label">{data}</label>
                </Col>
              )
            })}
        </Row>
      </Form>
      <Form>
        <Row className="form-group">
          <label>Hours</label>
        </Row>
        {weeklyHours &&
          weeklyHours.length > 0 &&
          weeklyHours.map((weeklyHrs, inx) => {
            return (
              <Row key={weeklyHrs.id + weeklyHrs.day}>
                <Col md={2}>{weeklyHrs.day}</Col>
                <Col md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={weeklyHrs.open24hrs}
                        onChange={() => changeTimeSlot('open24hrs', weeklyHrs.id)}
                        color="primary"
                      />
                    }
                    label={weeklyHrs.open24hrs ? 'Open 24 hours' : '24 Hours'}
                  />
                </Col>
                {!weeklyHrs.open24hrs && (
                  <Col md={2} key={weeklyHrs.id + 'isopen'}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={weeklyHrs.isOpen}
                          onChange={() => changeTimeSlot('isOpen', weeklyHrs.id)}
                          color="primary"
                        />
                      }
                      label={weeklyHrs.isOpen ? 'open' : 'closed'}
                    />
                  </Col>
                )}
                {weeklyHrs.isOpen && !weeklyHrs.open24hrs && (
                  <Col md={2} key={weeklyHrs.id + 'open'}>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={weeklyHrs.openTime}
                      drop={'down'}
                      className="time-slot-drop-down"
                    >
                      <div className="time-list">
                        {timeSlots.map((time) => {
                          return (
                            <Dropdown.Item
                              key={time + 'opening'}
                              onClick={() => changeTimeSlot('openTime', weeklyHrs.id, time)}
                            >
                              {time}
                            </Dropdown.Item>
                          )
                        })}
                      </div>
                    </DropdownButton>
                  </Col>
                )}
                {weeklyHrs.isOpen && !weeklyHrs.open24hrs && (
                  <Col md={1} key={weeklyHrs.id + 'close'}>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={weeklyHrs.closeTime}
                      drop={'down'}
                      className="time-slot-drop-down"
                    >
                      <div className="time-list">
                        {timeSlots.map((time) => {
                          return (
                            <Dropdown.Item
                              key={time + 'closing'}
                              onClick={() => changeTimeSlot('closeTime', weeklyHrs.id, time)}
                            >
                              {time}
                            </Dropdown.Item>
                          )
                        })}
                      </div>
                    </DropdownButton>
                  </Col>
                )}
              </Row>
            )
          })}
      </Form>

      <div className="text-center">
        {msg && msg !== 'New workspace created.' ? (
          <label className="text-center text-danger">{msg}</label>
        ) : (
          <label className="text-center text-success">{msg}</label>
        )}
      </div>
      {msg && msg === 'New workspace created.' ? null : (
        <div className="mb-3 text-center">
          <Button className="custom-outline-primary" onClick={handleSubmit}>
            Add Workspace
          </Button>
        </div>
      )}
    </Container>
  )
}

export default AddWorkSpace
