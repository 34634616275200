import { LoginUser } from '../../helper/api'
import { useAuth } from '../../helper/auth'

import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import { loginFields } from './formElements'

const LoginForm = () => {
  let auth = useAuth()
  const [FormData, setFormData] = useState({})
  const [status, setStatus] = useState({
    errMsg: '',
    errCls: '',
  })
  const handleChange = (e) => {
    let { name, value } = e.target
    if (status.errCls) {
      let updateStatus = { ...status }
      updateStatus.errCls = ''
      setStatus({ ...updateStatus })
    }
    let data = { ...FormData }
    data[name] = value
    setFormData(data)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (FormData['email'] && FormData['password']) {
      let qualifier = await LoginUser(FormData)
      if (qualifier.token) {
        auth.setUser(qualifier)
        auth.signin(() => window.location.reload())
      }
      if (qualifier.message) {
        let updateStatus = { ...status }
        updateStatus.errMsg = qualifier.message
        setStatus({ ...updateStatus })
      }
    } else {
      let updateStatus = { ...status }
      updateStatus.errCls = 'border-danger'
      setStatus({ ...updateStatus })
    }
  }

  let { errMsg, errCls } = status

  return (
    <Form>
      {loginFields.map((data, inx) => {
        let { name, type, placeholder, required, controlId } = data
        return (
          <Form.Group key={controlId + inx} controlId={controlId}>
            <Form.Control
              type={type}
              placeholder={placeholder}
              name={name}
              required={required}
              onChange={handleChange}
              className={errCls ? errCls : ''}
            />
          </Form.Group>
        )
      })}
      {errMsg ? <label className="text-danger">{errMsg}</label> : ''}
      <Form.Control
        type="submit"
        value="Login"
        className="bg-primary w-100"
        onClick={handleSubmit}
      />
    </Form>
  )
}

export default LoginForm
