import { suggestLocation } from '../../helper/api'

import React, { useState } from 'react'
import { Form, ListGroup } from 'react-bootstrap'

const AutoSuggest = ({
  label,
  name,
  placeholder,
  required,
  handleChange,
  styleInput,
  styleGroup,
  value,
  tip,
  suggestionType,
}) => {
  const [suggestions, setSuggestion] = useState([])
  const [status, setStatus] = useState({
    selected: value,
    city: '',
    state: '',
    country: '',
  })
  const handleSuggestion = async (e) => {
    e.preventDefault()
    let { value } = e.target

    let newStatus = { ...status }
    newStatus.selected = value
    setStatus({ ...newStatus })

    let suggests = await suggestLocation({ query: value })
    setSuggestion(suggests)
  }

  const handleClick = (e, data) => {
    e.preventDefault()
    let { locationName, locationData } = data
    let newStatus = { ...status }
    newStatus.selected = locationName
    newStatus.city = locationData.city
    newStatus.state = locationData.state
    newStatus.country = locationData.country
    setStatus({ ...newStatus })
    setSuggestion([])
    handleChange(e, {
      city: locationData.city,
      state: locationData.state,
      country: locationData.country,
    })
  }

  let { selected } = status

  return (
    <Form.Group controlId={name} className={styleGroup} title={tip}>
      {label ? <Form.Label>{label}</Form.Label> : ''}
      <Form.Control
        type="text"
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={handleSuggestion}
        className={styleInput}
        value={selected}
      />
      <div className="position-absolute">
        {suggestions.length > 0 &&
          suggestions
            .filter((data, inx) => inx < 5)
            .map((data, inx) => {
              let { locationName } = data

              return (
                <ListGroup key={locationName + inx}>
                  <ListGroup.Item action onClick={(e) => handleClick(e, data)}>
                    {locationName}
                  </ListGroup.Item>
                </ListGroup>
              )
            })}
      </div>
    </Form.Group>
  )
}

export default AutoSuggest
