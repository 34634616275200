import { profile } from '../../helper/api'

import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Redirect, Route, Switch } from 'react-router-dom'

import Sidebar from '../../components/sidebar/sidebar'

import TieUps from './sidebar/TieUps'
import TieupsTable from './sidebar/TieupsTable'
import Amenities from './sidebar/amenties'
import Analytics from './sidebar/analytics'
import BlogTable from './sidebar/blogTable'
import Blogs from './sidebar/blogs'
import Customers from './sidebar/customers'
import Database from './sidebar/database'
import DynamicUrls from './sidebar/dynamicUrls'
import Enquiry from './sidebar/enquiry'
import Profile from './sidebar/profile'
import Vendors from './sidebar/vendors'
import Workspace from './sidebar/workspace'

let ROUTES = [
  {
    name: 'Dashboard',
    path: '/analytics',
    page: Analytics,
  },
  {
    name: 'Dynamic Urls',
    path: '/dynamicUrls',
    page: DynamicUrls,
  },
  {
    name: 'Customer',
    path: '/customers',
    page: Customers,
  },
  {
    name: 'Vendor',
    path: '/vendors',
    page: Vendors,
  },
  {
    name: 'Work Spaces',
    path: '/workspaces',
    page: Workspace,
  },
  {
    name: 'Amenities',
    path: '/amenities',
    page: Amenities,
  },
  {
    name: 'Manage Profile',
    path: '/profile',
    page: Profile,
  },
  {
    name: 'Tie Ups',
    path: '/tieups',
    page: TieupsTable,
  },
  {
    name: 'Tie Ups',
    path: '/tieup/:id',
    page: TieUps,
  },
  {
    name: 'Blogs',
    path: '/blogs',
    page: BlogTable,
  },

  {
    name: 'Blogs',
    path: '/blog/:id',
    page: Blogs,
  },
  {
    name: 'Enquiry',
    path: '/enquiry',
    page: Enquiry,
  },
  {
    name: 'Database',
    path: '/database',
    page: Database,
  },
]

const Dashboard = () => {
  const [userAccess, setUseracess] = useState({})
  const [ROUTE, setRoute] = useState(ROUTES)

  // TO-DO: Push this to auth. Introduce reusability (Code repeated - 111)
  const getProfile = async () => {
    let user = await profile()
    setUseracess(user.data.userData)
    let {
      data: { userData },
    } = user

    if (userData.role !== 'ADMIN') {
      let VALID_ROUTE = ROUTES.filter((data, inx) => {
        let { name } = data
        return userData.feature_list.indexOf(name) >= 0
      })
      VALID_ROUTE.push(ROUTES[0])
      setRoute(VALID_ROUTE)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <>
      <Col lg={2} sm={2}>
        <Sidebar role={userAccess} />
      </Col>
      <Col>
        <main className="pt-5 mt-4">
          <Switch>
            {ROUTE.map((data, inx) => {
              let { path, page } = data
              return <Route key={'dashboard' + inx} path={'/dashboard' + path} component={page} />
            })}
            <Route render={() => <Redirect to={{ pathname: '/' }} />} />
          </Switch>
        </main>
      </Col>
    </>
  )
}

export default Dashboard
