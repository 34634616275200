import React from 'react'
import { Form } from 'react-bootstrap'

import AutoSuggest from './autosuggest'
import Datepicker from './datepicker'

const FormType = ({
  label,
  type,
  name,
  value,
  placeholder,
  required = false,
  disabled = false,
  multiple = false,
  options = [],
  styleGroup,
  styleInput,
  handleChange,
  textAreaRows = 3,
  min,
  max,
  format,
  tip,
}) => {
  let inputElement

  switch (type) {
    case 'select':
      inputElement = (
        <Form.Group controlId="exampleForm.ControlSelect1" className={styleGroup} title={tip}>
          {label ? <Form.Label>{label}</Form.Label> : ''}
          <Form.Control
            as="select"
            name={name}
            onChange={handleChange}
            disabled={disabled}
            defaultValue={value}
          >
            {options.map((data, inx) => {
              return <option key={data + inx}>{data}</option>
            })}
          </Form.Control>
        </Form.Group>
      )
      break
    case 'checkbox':
      inputElement = (
        <Form.Group controlId="formBasicCheckbox" className={styleGroup} title={tip}>
          {label ? <Form.Label>{label}</Form.Label> : ''}
          {options.map((data, inx) => {
            return (
              <Form.Check
                key={data + inx}
                type="checkbox"
                name={name}
                value={data}
                label={data}
                onChange={handleChange}
              />
            )
          })}
        </Form.Group>
      )
      break
    case 'radio':
      inputElement = (
        <Form.Group controlId="formBasicCheckbox" className={styleGroup} title={tip}>
          <Form.Check type="radio" name={name} label={label} onChange={handleChange} />
        </Form.Group>
      )
      break
    case 'file':
      inputElement = (
        <Form.Group controlId={name} className={styleGroup} title={tip}>
          {label ? <Form.Label>{label}</Form.Label> : ''}
          <Form.File
            label="Upload Images..."
            name={name}
            multiple={multiple}
            custom={true}
            onChange={handleChange}
          />
        </Form.Group>
      )
      break
    case 'autosuggest':
      inputElement = (
        <AutoSuggest
          label={label}
          name={name}
          placeholder={placeholder}
          required={required}
          handleChange={handleChange}
          styleGroup={styleGroup}
          styleInput={styleInput}
          value={value}
          tip={tip}
          suggestionType="LOCATION"
        />
      )
      break
    case 'textarea':
      inputElement = (
        <Form.Group controlId={name} className={styleGroup} title={tip}>
          {label ? <Form.Label>{label}</Form.Label> : ''}
          <Form.Control
            as="textarea"
            rows={textAreaRows}
            name={name}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            onChange={handleChange}
            className={styleInput}
          />
        </Form.Group>
      )
      break
    case 'date':
      inputElement = (
        <Form.Group controlId={name} className={styleGroup} title={tip}>
          {label ? <Form.Label>{label}</Form.Label> : ''}
          <br />
          <Datepicker name={name} handleChange={handleChange} format={format} min={min} max={max} />
        </Form.Group>
      )
      break
    default:
      inputElement = (
        <Form.Group controlId={name} className={styleGroup} title={tip}>
          {label ? <Form.Label>{label}</Form.Label> : ''}
          <Form.Control
            type={type}
            name={name}
            placeholder={placeholder}
            required={required}
            onChange={handleChange}
            className={styleInput}
            value={value}
            disabled={disabled}
          />
        </Form.Group>
      )
  }

  return inputElement
}

export default FormType
