import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import React from 'react'

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  heading: {
    marginBottom: '10px',
  },
})

export default function RangeSlider({ priceRange, onPriceRangeChange }) {
  const classes = useStyles()
  // const [value, setValue] = React.useState([0, 3000])

  // const handleChange = (event, newValue) => {
  //   console.log(newValue)
  //   setValue(newValue)
  // }
  const handlePriceRangeChange = (event, newPriceRange) => {
    console.log(newPriceRange)
    onPriceRangeChange(newPriceRange)
  }

  return (
    <div className={classes.root}>
      <Typography
        id="range-slider"
        gutterBottom
        className={classes.heading}
        style={{ marginBottom: '25px' }}
      >
        Open Desk Price Range
      </Typography>
      <Slider
        value={priceRange}
        onChange={handlePriceRangeChange}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        min={500}
        max={30000}
        step={500}
      />
    </div>
  )
}
