import { addCustomer, fetchCustomer, updateCustomer } from '../../../helper/api'

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

import InfoCard from '../../../components/cards/infoCard'
import { customerProfileStatus } from '../../../components/form/formElements'
import Form from '../../../components/form/newForm'
// Components
import ModalBox from '../../../components/modal'
import ModalWide from '../../../components/modal/wideModal'

// Page View and Information formatter
import { CustomerForm, RemarksForm } from './customerManager/customerHelper'

// Customer Main Component
const Customers = () => {
  const [formData, setFormData] = useState({})
  const [customerRequest, setCustomerRequests] = useState([])
  const [status, setStatus] = useState({
    showModal: false,
    showTracks: false,
    showProfile: false,
    errMsg: '',
    succMsg: '',
    fieldName: '',
    from: '',
  })

  const handleModal = (modalName, data) => {
    let updateStatus = { ...status }
    if (modalName === 'CREATE') {
      updateStatus.showModal = !status.showModal
      updateStatus.from = 'CREATE'
    } else if (modalName === 'PROFILE') {
      updateStatus.showModal = !status.showModal
      if (updateStatus.showModal === true) {
        updateStatus.from = 'PROFILE'
        setFormData({ ...data })
      } else {
        setFormData({})
      }
    } else if (modalName === 'TRACK') {
      updateStatus.showTracks = !status.showTracks
      if (updateStatus.showTracks === true) {
        updateStatus.from = 'TRACK'
        setFormData({ ...data })
      } else {
        setFormData({})
      }
    }
    updateStatus.errMsg = ''
    updateStatus.fieldName = ''
    updateStatus.succMsg = ''
    setStatus({ ...updateStatus })
  }

  const handleChange = (e) => {
    let { name, value, required } = e.target

    if (required && !value) {
      let newStatus = { ...status }
      newStatus['errMsg'] = '*Required Fields'
      newStatus['fieldName'] = name
      setStatus({ ...newStatus })
    } else {
      if (status.errMsg) {
        let newStatus = { ...status }
        newStatus['errMsg'] = ''
        newStatus['fieldName'] = ''
        setStatus({ ...newStatus })
      }
      let formValue = { ...formData }
      formValue[name] = value
      setFormData({ ...formValue })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (formData && formData.name && formData.source) {
      let insertStatus = await addCustomer(formData)
      let { customerStatus, message } = insertStatus
      let newStatus = { ...status }
      if (customerStatus && customerStatus._id) {
        newStatus['succMsg'] = message
        setStatus({ ...newStatus })
      } else {
        newStatus['succMsg'] = message
      }
      setStatus({ ...newStatus })
    } else {
      let newStatus = { ...status }
      newStatus['errMsg'] = 'Missing Required Fields'
      setStatus({ ...newStatus })
    }
  }

  const fetchCustomers = async (data) => {
    let customers = await fetchCustomer(data)
    setCustomerRequests(customers)
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    let { value } = e.target
    await fetchCustomers({ query: value })
  }

  const updateStatus = async (e, id, action) => {
    let { value } = e.target

    let formValues = {
      id: id,
      status: value,
    }
    if (value && id) {
      let res = await updateCustomer(formValues)
      if (!res.nModified) {
        let newStatus = { ...status }
        newStatus.fieldName = 'STATUS'
        newStatus.errMsg = 'Failed'
        setStatus({ ...newStatus })
      }
    }
  }

  const addRemarks = async (e) => {
    e.preventDefault()
    let { _id, date, remark } = formData

    let newStatus = { ...status }
    newStatus.fieldName = 'REMARK'
    if (date && remark) {
      let formValues = {
        id: _id,
        timeline: {
          date: date,
          remark: remark,
        },
      }
      let res = await updateCustomer(formValues)
      if (!res.nModified) {
        newStatus.errMsg = 'Failed'
      } else {
        newStatus.errMsg = 'Remark Added.'
      }
    } else {
      if (!date) {
        newStatus.errMsg = 'Select a date!'
      } else if (!remark) {
        newStatus.errMsg = 'Remark field cannot be empty!'
      }
    }
    setStatus({ ...newStatus })
  }

  useEffect(() => {
    fetchCustomers()
  }, [status])

  let { showModal, showTracks, errMsg, fieldName } = status

  return (
    <Container fluid={true}>
      <ModalBox
        title="Customer Request"
        show={showModal}
        handleClose={() => handleModal(status.from)}
        handleSubmit={handleSubmit}
        from={status.from}
        errMsg={errMsg}
        body={<CustomerForm handleChange={handleChange} status={status} formData={formData} />}
      />
      <ModalWide
        props={{
          show: showTracks,
          onHide: () => handleModal(status.from),
        }}
        title="Track Record"
        body={
          <RemarksForm
            handleChange={handleChange}
            handleSubmit={addRemarks}
            formData={formData}
            status={status}
          />
        }
      />
      <Row>
        <h1>Customer Information</h1>
      </Row>
      <Row className="justify-content-between">
        <Col className="">
          <input
            type="search"
            name="query"
            placeholder="Search"
            className="m-1 p-1 rounded-pill"
            onChange={handleSearch}
          />
          <i className="fas fa-search m-1"></i>
        </Col>
        <Col className="text-right">
          <Button variant="primary" onClick={() => handleModal('CREATE')} title="Add New Customer.">
            Add Customer
          </Button>
        </Col>
      </Row>
      <Row>
        {customerRequest.length > 0 &&
          customerRequest.map((data, inx) => {
            let { _id, name, designation, source, status } = data
            return (
              <InfoCard
                key={_id}
                title={name}
                subTitle={designation}
                body={
                  <div>
                    <label className="font-weight-bold">Lead:</label> {source}
                    <br />
                    {customerProfileStatus.status.map((data, inx) => {
                      let { label, name, type, options, value, disabled, tip } = data

                      if (status) {
                        value = status
                      }
                      if (status === 'SUCCESS' || status === 'FAILED') {
                        disabled = true
                      }

                      return (
                        <Form
                          key={_id + inx}
                          label={label}
                          name={name}
                          value={value}
                          type={type}
                          options={options}
                          disabled={disabled}
                          tip={tip}
                          handleChange={(e) => updateStatus(e, _id, 'STATUS')}
                        />
                      )
                    })}
                    {fieldName === 'STATUS' ? (
                      <label className="text-center text-danger">{errMsg}</label>
                    ) : (
                      ''
                    )}
                  </div>
                }
                options={[
                  {
                    handleClick: () => handleModal('PROFILE', data),
                    linkName: 'View Profile',
                    tip: 'View Information of Vendor',
                  },
                  {
                    handleClick: () => {
                      handleModal('TRACK', data)
                    },
                    linkName: 'Track',
                    tip: 'Add Remarks and Updates.',
                  },
                ]}
              />
            )
          })}
      </Row>
    </Container>
  )
}

export default Customers
