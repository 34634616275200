import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'

import { createSpace, spaceProfile } from '../../../../components/form/formElements'
import Form from '../../../../components/form/newForm'

const hideImage = { opacity: 0.4 }
const showImage = { opacity: 1 }
const ImageViewing = ({ modalData, handleUpload, msg, handleDelete, deleteMsg }) => {
  const [imageDeleteList, setDeleteList] = useState([])
  const deleteImages = () => {
    if (imageDeleteList && imageDeleteList.length > 0) {
      handleDelete(imageDeleteList, modalData._id)
    }
  }
  const prepareDelete = (url) => {
    let newImageDeleteList = [...imageDeleteList]
    let targetIndex = newImageDeleteList.indexOf(url)
    if (targetIndex >= 0) {
      newImageDeleteList.splice(targetIndex, 1)
    } else {
      newImageDeleteList.push(url)
    }
    setDeleteList(newImageDeleteList)
  }
  return (
    <>
      {/* <Row>
        <Col>
          {spaceProfile.map((data, inx) => {
            let { label, name, type } = data;
            return (
              <input
                key={name + inx}
                label={label}
                name={name}
                type={type}
                multiple={true}
                onChange={handleUpload}
                title="Only jpg, jpeg, png files are allowed."
              />
            );
          })}
          {msg ? <label className="text-success text-center">{msg}</label> : ""}
        </Col>
      </Row> */}
      {/* <hr /> */}
      <Row xs={1} lg={3} className="m-1 p-1">
        {modalData && modalData.images
          ? modalData.images.map((data, inx) => {
              return (
                <Col className="mt-4" key={data + inx}>
                  <Image
                    src={data}
                    className="p-2 w-100 h-100"
                    style={imageDeleteList.includes(data) ? hideImage : showImage}
                    rounded
                  />
                  <div className="text-center" onClick={() => {}}>
                    {/* {imageDeleteList.includes(data) ? (
                      <i className="fas fa-undo"></i>
                    ) : (
                      <i className="fas fa-trash-alt"></i>
                    )} */}
                  </div>
                </Col>
              )
            })
          : null}
      </Row>
      <Row className="mt-4 text-center"></Row>
    </>
  )
}

export default ImageViewing
