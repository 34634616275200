import { getTieupDetails, updateTieup, uploadTieups } from '../../../helper/api'

import React, { useEffect, useRef, useState } from 'react'
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form, //FormFile,
  ListGroup,
  ListGroupItem,
  Row,
} from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

const VALID_TYPES = ['image/png', 'image/jpeg', 'image/jpg']

export default function TieUps() {
  let { id } = useParams()
  let history = useHistory()
  const editorRef = useRef()
  const [content, setContent] = useState('')
  const [state, setState] = useState('')
  const [tieupData, setTieupData] = useState([])

  const [title, setTitle] = useState('')
  const [shortDescription, setShortDescription] = useState('')
  const [longDescription, setLongDescription] = useState('')
  const [address, setAddress] = useState('')
  const [contact, setContact] = useState('')
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [location, setLocation] = useState('')
  const [cardImage, setCardImage] = useState('')
  const [fileErrors, setfileError] = useState('')
  const [formSubmited, setFormSubmited] = useState(false)
  const [error, setError] = useState({ type: '', text: '' })

  useEffect(() => {
    if (id == 'add') {
      setState('add')
    } else {
      getDetails(id)
    }
  }, [id])

  const getDetails = async (id) => {
    let tieupData = await getTieupDetails({ id: id })

    if (tieupData?.data?._id) {
      setTieupData(tieupData?.data)
    } else {
      history.push('/dashboard/tieups')
    }
  }

  useEffect(() => {
    if (tieupData?._id) {
      setTitle(tieupData?.title)
      setShortDescription(tieupData?.short_description)
      setLongDescription(tieupData?.long_description)
      setAddress(tieupData?.address)
      setContact(tieupData?.contact)
      setWebsiteUrl(tieupData?.website_url)
      setLocation(tieupData?.location)
      setCardImage(tieupData?.cardImage)
    }
  }, [tieupData])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let tieupData = {
      title: title,
      shortDescription: shortDescription,
      longDescription: longDescription,
      address: address,
      contact: contact,
      websiteUrl: websiteUrl,
      location: location,
      file: cardImage,
    }

    if (state == 'add') {
      tieupData['id'] = ''
    } else {
      tieupData['id'] = tieupData?._id
    }

    try {
      if (fileErrors === '' && !formSubmited) {
        let data
        if (state == 'add') {
          data = await uploadTieups(tieupData)
        } else {
          data = await updateTieup(tieupData)
        }
        console.log(tieupData)
        // const { data } = await uploadTieups(tieupData)
        console.log(data + 'data')
        if (data.error) {
          setError({ type: 'error', text: data.message })
        } else {
          setError({ type: 'success', text: data.message })
          setFormSubmited(true)
          setTimeout(() => {
            history.push('/dashboard/tieups')
          }, 2000)
        }
      }
    } catch (error) {
      console.log(error)
      setError({ type: 'error', text: error.message })
      throw error
    }
  }

  const handleChangeImage = async (e) => {
    if (state !== 'add') {
      // setfileError('Please save the data first')
      return
    } else if (!e.target.files || e.target.files.length === 0) {
      setfileError('Please select a file')
      return
    } else if (e.target.files[0].size / 1024 >= 5 * 1024) {
      setfileError('File should not be greater than 5 MB')
      return
    } else if (!VALID_TYPES.includes(e.target.files[0].type)) {
      setfileError('File format should be jpeg, jpg, png')
      return
    } else if (e.target.files) {
      setfileError('')
      var file = e.target.files[0]
      setCardImage(file)
    }
  }

  return (
    <>
      <Form.Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Tie Ups</Card.Title>
            </Card.Header>
            <Card.Body>
              <Alert show={error.type === 'error'} variant="danger">
                {error.text}
              </Alert>
              <Alert show={formSubmited} variant="success">
                <Alert.Heading>Form Submited Sucessfully !</Alert.Heading>
              </Alert>
              <Form validated={formSubmited} onSubmit={handleSubmit}>
                <Form.Row>
                  <Col className="pr-1" md={6}>
                    <Form.Group>
                      <Form.Label>Card Title</Form.Label>
                      <Form.Control
                        size="lg"
                        placeholder="Card Title"
                        type="text"
                        required
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Title.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="px-1" md={6}>
                    <Form.Group>
                      <Form.Label>Card short description</Form.Label>
                      <Form.Control
                        placeholder="Card short description"
                        type="text"
                        required
                        value={shortDescription}
                        onChange={(e) => setShortDescription(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-1" md={6}>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Card long description</Form.Label>
                      <Form.Control
                        placeholder="Card long description"
                        as="textarea"
                        required
                        rows={2}
                        value={longDescription}
                        onChange={(e) => setLongDescription(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-1" md={6}>
                    <Form.Group>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        placeholder="Address"
                        type="text"
                        required
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="px-1" md={6}>
                    <Form.Group>
                      <Form.Label>Contact no.</Form.Label>
                      <Form.Control
                        placeholder="Contact no."
                        type="tel"
                        required
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col className="pr-1" md={6}>
                    <Form.Group>
                      <Form.Label>Website url</Form.Label>
                      <Form.Control
                        placeholder="https://www.example.com"
                        type="url"
                        required
                        value={websiteUrl}
                        onChange={(e) => setWebsiteUrl(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="px-1" md={6}>
                    <Form.Group>
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        placeholder="Location"
                        type="text"
                        required
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <div className="mb-3">
                    <Form.File id="formcheck-api-regular">
                      <Form.File.Label>Upload Card Image</Form.File.Label>
                      <Form.File.Input
                        onChange={handleChangeImage}
                        required={state === 'add' ? true : false}
                      />
                    </Form.File>
                    {fileErrors && (
                      <div className="text-left">
                        <label className="text-center text-danger">{fileErrors}</label>
                      </div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid File.
                    </Form.Control.Feedback>
                  </div>
                </Form.Row>

                {/* <Form.Row> */}
                {/* <Col md={12}> */}
                <Button type="submit">{state == 'add' ? 'Submit' : 'Update'}</Button>
                {/* </Col> */}
                {/* </Form.Row> */}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Form.Row>

      {/* submit button */}
    </>
  )
}
