import { addAmenty, uploadSpaceImages } from '../../../helper/api'

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'

import { amentyDetails, amentyImageDetails } from '../../../components/form/formElements'

const VALID_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg']
const HandleAmenty = () => {
  const [status, setStatus] = useState({
    name: '',
    msg: '',
    amentyId: '',
    imageURL: '',
  })

  const handleChange = (e) => {
    let newStatus = { ...status }
    newStatus.name = e.target.value
    setStatus(newStatus)
  }
  const handleUpload = async (e) => {
    let { files = [] } = e.target
    let newStatus = { ...status }
    if (VALID_TYPES.indexOf(files[0].type) > -1) {
      let params = {
        file: files[0],
        id: status.amentyId,
        source: 'amenty',
      }
      let res = await uploadSpaceImages(params)
      if (res && res.url) {
        newStatus.msg = 'Image uploaded for amenty ' + status.name
        newStatus.imageURL = res.url
      }
    }
    setStatus(newStatus)
  }

  const handleSubmit = async () => {
    let params = {
      name: status.name,
    }
    let res = await addAmenty(params)
    let newStatus = { ...status }
    newStatus.msg = res.data.message
    if (res.data.message === 'New amenty created.') {
      newStatus.amentyId = res.data.id
    }
    setStatus(newStatus)
  }
  return (
    <Container fluid={true}>
      <Form>
        {status.imageURL && status.imageURL !== '' ? (
          <div className="text-center">
            <Image src={status.imageURL} className="p-2 w-25 h-25" />
          </div>
        ) : status.amentyId && status.amentyId !== '' ? (
          amentyImageDetails.map((data, inx) => {
            let { label, name, type } = data
            return (
              <input
                key={name + inx}
                label={label}
                name={name}
                type={type}
                onChange={handleUpload}
                title="Only jpg, jpeg, png files are allowed."
              />
            )
          })
        ) : (
          amentyDetails.map((data, inx) => {
            let { label, name, type, placeholder, required } = data
            return (
              <Row key={name + inx} className="form-group">
                <label>{label}</label>
                <input
                  className="form-control"
                  type={type}
                  name={name}
                  value={status.name}
                  onChange={handleChange}
                  placeholder={placeholder}
                  required={required}
                />
              </Row>
            )
          })
        )}
      </Form>
      <div className="text-center">
        {status.msg &&
        (status.msg !== 'New amenty created.' ||
          status.msg === 'Image uploaded for amenty ' + status.name) ? (
          <label className="text-center text-danger">{status.msg}</label>
        ) : (
          <label className="text-center text-success">{status.msg}</label>
        )}
      </div>
      {status.msg &&
      (status.msg === 'New amenty created.' ||
        status.msg === 'Image uploaded for amenty ' + status.name) ? null : (
        <div className="mb-3 text-center">
          <Button className="custom-outline-primary" onClick={handleSubmit}>
            Add Amenty
          </Button>
        </div>
      )}
    </Container>
  )
}

export default HandleAmenty
