import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const Datepicker = ({ name = 'date', min, max, format = 'dd/MM/yyyy', handleChange }) => {
  const [date, setDate] = useState(new Date())
  const handleDateSelect = (e) => {
    setDate(e)
  }
  const handleSelected = (e) => {
    handleChange({
      target: {
        name: name,
        value: date,
      },
    })
  }
  return (
    <DatePicker
      selected={date}
      dateFormat={format}
      onSelect={handleDateSelect}
      onChange={handleSelected}
    />
  )
}

export default Datepicker
