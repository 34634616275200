import React from 'react'
import { Card } from 'react-bootstrap'

const InfoCard = ({ title, subTitle, body, options = [] }) => {
  return (
    <Card className="m-1 p-1">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="text-muted">{subTitle}</Card.Subtitle>
        <Card.Text>{body}</Card.Text>
        {options.map((data, inx) => {
          let { handleClick, href = '#', linkName, tip } = data

          return (
            <Card.Link
              key={linkName + inx}
              href={href}
              target={href !== '#' ? '_blank' : ''}
              onClick={handleClick}
              title={tip}
            >
              {linkName}
            </Card.Link>
          )
        })}
      </Card.Body>
    </Card>
  )
}

export default InfoCard
