import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

import RemarksCard from '../../../../components/cards/RemarksCard'
import { customerForm, customerProfileStatus } from '../../../../components/form/formElements'
import Form from '../../../../components/form/newForm'

export const CustomerForm = ({ handleChange, status, formData }) => {
  let { errMsg, fieldName, from } = status
  let styleGroup = ''

  return (
    <>
      {customerForm.map((data, inx) => {
        let { label, name, value, type, placeholder, required, disabled } = data
        if (placeholder === 'Username') {
          placeholder = 'Email'
        }

        if (from === 'PROFILE') {
          value = formData[name]
          disabled = true
        }
        return (
          <div key={name + inx}>
            <Form
              key={name + inx}
              label={label}
              name={name}
              type={type}
              value={value}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              handleChange={handleChange}
              styleGroup={styleGroup}
              styleInput={fieldName === name ? 'border-danger' : ''}
            />
            {fieldName === name && errMsg ? <label className="text-danger">{errMsg}</label> : ''}
          </div>
        )
      })}
      {status.succMsg && status.succMsg === 'Success' ? (
        <label className="text-center text-success">{status.succMsg}</label>
      ) : (
        <label className="text-center text-danger">{status.succMsg}</label>
      )}
    </>
  )
}

export const RemarksForm = ({ handleChange, handleSubmit, formData, status }) => {
  let { fieldName, errMsg } = status
  return (
    <>
      <Row>
        <Col>
          {formData.timeline &&
            formData.timeline.length > 0 &&
            formData.timeline.map((data, inx) => {
              let { date, remark } = data
              return <RemarksCard key={inx} main={remark} footer={new Date(date).toDateString()} />
            })}
        </Col>
      </Row>
      <Row lg={3}>
        {customerProfileStatus.Remark.map((data, inx) => {
          let { label, name, type, placeholder, value, min, max } = data
          return (
            <Col key={name + inx} lg={type === 'date' ? 3 : 6}>
              <Form
                key={name + inx}
                label={label}
                name={name}
                type={type}
                placeholder={placeholder}
                value={value}
                min={min}
                max={max}
                handleChange={handleChange}
              />
            </Col>
          )
        })}
        <Col lg={1} className="align-self-center mt-3">
          <Button onClick={handleSubmit}>Add</Button>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          {fieldName === 'REMARK' && errMsg !== 'Remark Added.' ? (
            <label className="text-center text-danger">{errMsg}</label>
          ) : (
            ''
          )}
          {fieldName === 'REMARK' && errMsg === 'Remark Added.' ? (
            <label className="text-center text-success">{errMsg}</label>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </>
  )
}
