import { deleteAmenity, getAllAmenities, uploadSpaceImages } from '../../../helper/api'

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'

import { amentyImageDetails } from '../../../components/form/formElements'
import ModalWide from '../../../components/modal/wideModal'

import HandleAmenty from './handleAmenty'

const VALID_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg']

const Amenities = () => {
  const [amenties, setAmenties] = useState([])
  const [status, setStatus] = useState({
    showModal: false,
    uploadedFiles: [],
    loading: true,
    succMsg: '',
  })

  const getAmenties = async () => {
    let amenties = await getAllAmenities()
    setAmenties(amenties.allAmenities)
    console.log(amenties.allAmenities)
  }

  const handleModal = () => {
    let newStatus = { ...status }
    newStatus.showModal = !newStatus.showModal
    setStatus({ ...newStatus })
  }

  const handleDelete = async (amenityToDelete) => {
    console.log(amenityToDelete)
    let params = {
      id: amenityToDelete._id,
      image: amenityToDelete.image ? amenityToDelete.image : null,
    }
    let res = await deleteAmenity(params)
    if (res.data.message.includes('Deleted')) getAmenties()
  }

  const handleUpload = async (e, name, id) => {
    let { files = [] } = e.target
    let newStatus = { ...status }
    console.log(id)
    if (VALID_TYPES.indexOf(files[0].type) > -1) {
      let params = {
        file: files[0],
        id: id,
        source: 'amenty',
      }
      let res = await uploadSpaceImages(params)
      if (res && res.url) {
        newStatus.succMsg = 'Image uploaded for amenty ' + name
      }
    }
    setStatus(newStatus)
  }

  useEffect(() => {
    getAmenties()
  }, [])

  let { showModal, loading, succMsg } = status

  return (
    <Container fluid={true}>
      <ModalWide
        props={{
          show: showModal,
          onHide: () => handleModal(),
        }}
        title="Amenity Details"
        body={<HandleAmenty />}
      />
      <Row>
        <h1>Amenties</h1>
      </Row>
      <Row className="justify-content-between">
        <Col className="">
          <input type="search" name="query" placeholder="Search" className="m-1 p-1" />
          <i className="fas fa-search m-1"></i>
        </Col>
        <Col className="d-flex flex-row-reverse m-1">
          <Button variant="outline-secondary" onClick={() => handleModal()}>
            Add Amenities
          </Button>
        </Col>
      </Row>
      <Row lg={1}>
        {amenties.length > 0 &&
          amenties.map((data, inx) => {
            return (
              <Row className="m-1 p-1" key={name + inx}>
                <Col className="h6">
                  <label className="font-weight-bold">{data.name}</label>
                </Col>
                <Col>
                  {data.image ? (
                    <img
                      src={data.image}
                      className="img-thumbnail"
                      style={{ width: '5rem', height: '5rem' }}
                    />
                  ) : status.succMsg && status.succMsg.includes(data.name) ? (
                    <label className="text-center text-success">{status.msg}</label>
                  ) : (
                    amentyImageDetails.map((uploadingData, inx) => {
                      let { label, name, type } = uploadingData
                      return (
                        <input
                          key={name + inx}
                          label={label}
                          name={name}
                          type={type}
                          onChange={(e) => handleUpload(e, data.name, data._id)}
                          title="Only jpg, jpeg, png files are allowed."
                        />
                      )
                    })
                  )}
                </Col>
                <Col>
                  <Button variant="outline-secondary" onClick={() => handleDelete(data)}>
                    Delete
                  </Button>
                </Col>
              </Row>
            )
          })}
      </Row>
    </Container>
  )
}

export default Amenities
