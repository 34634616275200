import { getVendors } from '../../../helper/api'

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import InfoCard from '../../../components/cards/infoCard'
import { createSpace, vendorProfileStatus } from '../../../components/form/formElements'
import Form from '../../../components/form/newForm'
import ModalBox from '../../../components/modal'

const Vendors = () => {
  let router = useHistory()
  const [vendors, setVendorData] = useState([])
  const [status, setStatus] = useState({
    showModal: false,
    vendorData: {},
  })

  const handleModal = (data) => {
    let newStatus = { ...status }
    newStatus.showModal = !status.showModal
    newStatus.vendorData = data
    setStatus({ ...newStatus })
  }

  const handleClick = () => {
    router.push('/createVendor')
  }

  const fetchVendorData = async (query) => {
    let data = await getVendors(query)
    setVendorData(data)
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    let { value } = e.target
    await fetchVendorData({ query: value })
  }

  useEffect(() => {
    fetchVendorData()
  }, [])

  let { showModal, vendorData } = status

  return (
    <Container fluid={true}>
      <ModalBox
        title="Vendor Data"
        show={showModal}
        handleClose={() => handleModal()}
        from="PROFILE"
        body={createSpace['Vendor Details'].map((data, inx) => {
          let { label, name, value, placeholder, type, required, disabled } = data

          if (vendorData && vendorData[name]) {
            value = vendorData[name]
            disabled = true
          }

          return (
            <Form
              key={name + inx}
              label={label}
              name={name}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              type={type}
              required={required}
            />
          )
        })}
      />
      <Row>
        <h1>Vendors Information</h1>
      </Row>
      <Row className="justify-content-between">
        <Col className="">
          <input
            type="search"
            name="query"
            placeholder="Search"
            className="m-1 p-1"
            onChange={handleSearch}
          />
          <i className="fas fa-search m-1"></i>
        </Col>
        <Col className="text-right">
          <Button variant="primary" onClick={handleClick}>
            Create Vendor
          </Button>
        </Col>
      </Row>
      <Row lg={4} sm={1}>
        {vendors.vendors &&
          vendors.vendors.length > 0 &&
          vendors.vendors.map((data, inx) => {
            let {
              _id,
              name,
              designation,
              // email,
              // mobile
            } = data

            return (
              <InfoCard
                key={_id + inx}
                title={name}
                subTitle={designation}
                body={
                  <div>
                    <label className="font-weight-bold"> Spaces:</label> Cubic, Bunchwork, P-square
                    <br />
                    {vendorProfileStatus.map((data, inx) => {
                      let { label, name, type, placeholder, options } = data

                      return (
                        <Form
                          key={name + inx}
                          label={label}
                          name={name}
                          type={type}
                          placeholde={placeholder}
                          options={options}
                        />
                      )
                    })}
                  </div>
                }
                options={[
                  {
                    handleClick: () => handleModal(data),
                    linkName: 'View Profile',
                  },
                ]}

                //   {hadleClick: ()=>handleClick(data), linkName: "Add Workspace"}
              />
            )
          })}
      </Row>
    </Container>
  )
}

export default Vendors
