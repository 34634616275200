import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const ModalBox = ({
  title,
  body,
  show,
  errMsg,
  handleClose,
  handleSubmit,
  successText = 'Save',
  closeText = 'Close',
  from,
}) => {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{body}</Modal.Body>
        {errMsg ? <label className="text-center text-danger">{errMsg}</label> : ''}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {closeText}
          </Button>
          {from !== 'PROFILE' ? (
            <Button variant="primary" onClick={handleSubmit}>
              {successText}
            </Button>
          ) : (
            ''
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalBox
