import { profile } from '../../helper/api'

import React, { useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link, Route, useLocation } from 'react-router-dom'

const SIDEBAR_OPTIONS = [
  {
    name: 'Dashboard',
    icon: 'fas fa-tachometer-alt',
    route: '/dashboard/analytics',
  },

  // karan code
  {
    name: 'Dynamic Urls',
    icon: 'fas fa-users',
    route: '/dashboard/dynamicUrls',
  },
  // {
  //   name: "Customer",
  //   icon: "fas fa-users",
  //   route: "/dashboard/customers",
  // },
  // {
  //   name: "Vendor",
  //   icon: "fas fa-user-friends",
  //   route: "/dashboard/vendors",
  // },
  {
    name: 'Work Spaces',
    icon: 'fas fa-store',
    route: '/dashboard/workspaces',
  },

  // {
  //   name: "Amenities",
  //   icon: "fas fa-hotel",
  //   route: "/dashboard/amenities",
  // },
  // {
  //   name: "Manage Profile",
  //   icon: "fas fa-user-circle",
  //   route: "/dashboard/profile",
  // },

  //rushab.in
  {
    name: 'Tie Ups',
    icon: 'fas fa-handshake',
    route: '/dashboard/tieups',
  },
  {
    name: 'Blogs',
    icon: 'fas fa-blog',
    route: '/dashboard/blogs',
  },
  {
    name: 'Enquiry',
    icon: 'fas fa-blog',
    route: '/dashboard/enquiry',
  },
  {
    name: 'Database',
    icon: 'fas fa-blog',
    route: '/dashboard/database',
  },
]

const Sidebar = () => {
  let { pathname } = useLocation()

  let [userAccess, setUseraccess] = useState({})

  const [ROUTE, setRoute] = useState(SIDEBAR_OPTIONS)

  // TO-DO: Push this to auth. Introduce reusability (Code repeated - 111)
  const handleAccess = async () => {
    let user = await profile()
    setUseraccess(user.data.userData)
    let { role, feature_list } = user.data.userData
    if (role !== 'ADMIN') {
      let VALID_ROUTE = ROUTE.filter((data, inx) => {
        let { name } = data
        return feature_list.indexOf(name) >= 0
      })
      VALID_ROUTE.push(ROUTE[0])
      setRoute(VALID_ROUTE)
    }
  }

  useEffect(() => {
    handleAccess()
  }, [])
  return (
    <Navbar className="d-block" expand="lg">
      <Navbar.Collapse id="sidebarMenu">
        <Nav className="sidenav flex-column">
          {ROUTE.map((data, inx) => {
            let { name, icon, route } = data
            return (
              <Nav.Item key={name + inx}>
                <Link to={route} className={pathname === route ? 'active' : ''}>
                  <i className={'m-1 p-1 ' + icon}></i> {name}
                </Link>
              </Nav.Item>
            )
          })}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Sidebar
