import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import LoginForm from '../../components/form'

const Login = () => {
  return (
    <Container className="pt-2 pl-3">
      <Row>
        <Col>
          <h1 className="pt-5 mt-3">
            Login to
            <br /> Continue
          </h1>
        </Col>
      </Row>
      <Row className="formHeight align-items-end">
        <Col lg={3}>
          <LoginForm />
        </Col>
      </Row>
    </Container>
  )
}

export default Login
