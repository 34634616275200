import { createClientLink, getSpaces } from '../../../helper/api'
import { MenuProps, useStyles } from '../../../helper/util'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Row } from 'react-bootstrap'

import 'bootstrap/dist/css/bootstrap.min.css'

import RemarksCard from '../../../components/cards/RemarksCard'
import ModalWide from '../../../components/modal/wideModal'

import { Dropdown, Option } from './Dropdown/Dropdown'
import RangeSlider from './slider/Slider'
import AddWorkSpace from './workSpaceManager/addWorkspace'
import ImageViewing from './workSpaceManager/imageViewing'
import WorkspaceHandling from './workSpaceManager/workSpaceHandling'

// spent hours on this issue: <10
// https://github.com/vitejs/vite/issues/2139
// eslint-disable-next-line
// prettier-ignore
// @ts-ignore
// const MultiRangeSlider = MRST.default ? MRST.default : MRST

const highlightWorkspae = { border: 'dashed green'}
const showWorkspace = {}

function DynamicUrls() {
  // Everything workspace
  const [showCreateLinkAlert, setShowCreateLinkAlert] = useState(false)
  const [createLinkMsg, setCreateLinkMsg] = useState('')

  const [createdLink, setCreatedLink] = useState('')
  const [allSpaces, setAllSpaces] = useState([])

  const [allSelected, setSelected] = useState([])

  const [allCities, setCities] = useState([])
  const [areaArray, setAreaArray] = useState([])

  const [countSelected, setCountSelected] = useState(0)

  const [arrayIdsNames, setArrayIdsNames] = useState([
    { id: 1, name: '' },
    { id: 1, name: '' },
    { id: 1, name: '' },
    { id: 1, name: '' },
    { id: 1, name: '' },
  ])

  const [spaces, setSpaces] = useState([])
  const [filteredWorkspaces, setFilteredWorkspaces] = useState([])
  const [status, setStatus] = useState({
    showModal: false,
    modalData: {},
    modalType: '',
    uploadedFiles: [],
    deletedFiles: 0,
    succMsg: '',
    deleteMsg: '',
    spaceDeleteMsg: '',
    toBeDeletedWorkspace: [],
  })

  const clearAll = () => {
    setShowCreateLinkAlert(false)
    setCreateLinkMsg('')
    setCreatedLink('')
    setSelected([])
    setSelected2([])
    setCountSelected(0)
    setCityValue('Mumbai')
    setPriceRange([0, 30000])
    setFormData({})
    setStatus({
      showModal: false,
      modalData: {},
      modalType: '',
      uploadedFiles: [],
      deletedFiles: 0,
      succMsg: '',
      deleteMsg: '',
      spaceDeleteMsg: '',
      toBeDeletedWorkspace: [],
    })
    clearSelections()
  }
  const clearSelections = () => {
    navigator.clipboard.writeText(``)
    let resetArray = [
      { id: 1, name: '' },
      { id: 1, name: '' },
      { id: 1, name: '' },
      { id: 1, name: '' },
      { id: 1, name: '' },
    ]
    setCountSelected(0)
    let emptyArray = [false, false, false, false, false, false, false, false, false, false]
    setSelected(emptyArray)
    setArrayIdsNames(resetArray)
  }
  const handleModal = (data, type) => {
    let newStatus = { ...status }
    newStatus.showModal = !newStatus.showModal
    if (type === 'IMAGES' || type === 'EDITWORKSPACE' || type === 'ADDWORKSPACE') {
      newStatus.modalType = type
    } else {
      newStatus.modalType = ''
    }

    if (newStatus.showModal === true && type != 'ADDWORKSPACE') {
      newStatus.modalData = { ...data }
    } else {
      newStatus.modalData = {}
      newStatus.uploadedFiles = []
      newStatus.succMsg = ''
      newStatus.deletedFiles = 0
      newStatus.deleteMsg = ''
    }

    setStatus({ ...newStatus })
  }

  function findWorkSpacesInCity(workspace) {
    // console.log('Single workspace', workspace)
    // console.log('City', this)
    return workspace.address.city === this
  }

  const getAreasInCity = (mySpaces) => {
    let myAreas = [...new Set(mySpaces.map((item) => item.address.area))]
    setAreaArray(myAreas)
  }

  const getWorkSpaces = async (query) => {
    let space = await getSpaces(query)
    if (space) {
      setAllSpaces(space)
      setSpaces(space)
      let myCities = [...new Set(space.map((item) => item.address.city))]
      setCities(myCities)
      setCityValue(myCities[0])
      setFilteredWorkspaces(space.filter(findWorkSpacesInCity, myCities[0]))
      getAreasInCity(space.filter(findWorkSpacesInCity, myCities[0]))
    }
  }

  let { showModal, modalData, modalType, succMsg, deleteMsg } = status

  // workspace ends
  const [FormData, setFormData] = useState({})
  //const [cities, setCities] = useState([]);
  // const [data, setData] = useState([])
  console.log(FormData, 'formdata')

  const [cityValue, setCityValue] = useState('Mumbai')
  const [areaValue, setAreaValue] = useState('')
  const classes = useStyles()
  const [selected, setSelected2] = useState([])
  const isAllSelected = areaArray.length > 0 && selected.length === areaArray.length

  const handleChange2 = (event) => {
    const value = event.target.value
    console.log(value)
    let newSpaces
    if (value[value.length - 1] === 'all') {
      if (value.length === areaArray.length + 1) {
        setSelected2([])
        newSpaces = spaces.filter((workspace) => {
          return cityValue === workspace.address.city
        })
        setFilteredWorkspaces(newSpaces)
      } else {
        setSelected2(areaArray)
        newSpaces = spaces.filter((workspace) => {
          return cityValue === workspace.address.city
        })
        setFilteredWorkspaces(newSpaces)
      }
      return
    }
    setSelected2(value)
    if (value.length !== 0) {
      console.log('length', value.length)
      let data = { ...FormData }
      data['area'] = value
      setFormData(data)
      let areaWorkspaces = []
      spaces.forEach((workspace) => {
        if (
          cityValue === workspace.address.city &&
          value.map((e) => e?.toLowerCase()).includes(workspace.address.area?.toLowerCase())
        ) {
          areaWorkspaces.push(workspace)
        }
      })

      console.log('areaWorkspaces', areaWorkspaces)
      setFilteredWorkspaces(areaWorkspaces)
    }
  }

  const [priceRange, setPriceRange] = useState([0, 30000])

  // const [clientName, setClientName] = useState('')
  // slider
  const handlePriceRangeChange = (newPriceRange) => {
    setPriceRange(newPriceRange)

    // filter by price range
    let selAreaWorkSpaces = spaces.filter((workspace) => {
      console.log('workspace', workspace)
      return (
        cityValue === workspace.address.city &&
        selected?.includes(workspace.address.area) &&
        workspace?.price?.open_desk > newPriceRange[0] &&
        workspace?.price?.open_desk < newPriceRange[1]
      )
    })
    console.log('selAreaWorkSpaces', selAreaWorkSpaces)
    setFilteredWorkspaces(selAreaWorkSpaces)
  }

  const handleChange = (e) => {
    let { name, value } = e.target
    let data = { ...FormData }
    data[name] = value
    setFormData(data)
    //setClientName(value);
  }

  useEffect(() => {
    getWorkSpaces()
  }, [])

  const handleSelectWorkspace = (data, inx) => {
    // allSelected = [false, false, false, true]
    // inx = 2
    //let inc = 2;
    let copy = allSelected
    // console.log("Start of allSelected");
    // console.log(allSelected);
    let updatedCopy = copy.map((element, itr) => (itr === inx ? !copy[itr] : copy[itr]))
    // console.log("This is selected array");
    // console.log(updatedCopy);
    setSelected(updatedCopy)
    setCreatedLink('')
  }

  async function handleSelectCity(e) {
    //console.log("I am selecting", e.target.value);
    //console.log(e.target.)
    let data = { ...FormData }
    //console.log("Form data before", data);
    data['city'] = e.target.value
    //console.log("Form data after", data);
    setFormData(data)
    setSpaces(allSpaces)
    setPriceRange(priceRange)
    setSelected2([])
    setCityValue(e.target.value)
    // find workspaces in city
    console.log('spaces', spaces)
    console.log('allSpaces', allSpaces)
    let newCityWorkspaces = []
    spaces.forEach((workspace) => {
      if (workspace.address.city === e.target.value) {
        console.log('matched')
        newCityWorkspaces.push(workspace)
      } else {
        console.log('not matched')
      }
    })
    getAreasInCity(newCityWorkspaces)
    setFilteredWorkspaces(newCityWorkspaces)
    console.log('newCityWorkspaces', newCityWorkspaces)
  }

  const handleSelectArea = async (e) => {
    //console.log("This is my area", e.target.value);
    let data = { ...FormData }
    //console.log("Form data before", data);
    data['area'] = e.target.value
    //console.log("Form data after", data);
    setFormData(data)

    setAreaValue(e.target.value)

    let selAreaWorkSpaces = spaces.filter((workspace) => {
      //console.log("Searchin for ", e.target.value);
      return workspace.address.area === e.target.value
    })
    setFilteredWorkspaces(selAreaWorkSpaces)
    let falseArray = selAreaWorkSpaces.map((ws, itr) => {
      //console.log(itr);
      return false
    })

    setSelected(falseArray)
  }

  const handleCreateLink = async () => {
    console.log(FormData)
    if (countSelected > 0 && FormData) {
      if (!FormData.clientName) {
        setShowCreateLinkAlert(true)
        setCreateLinkMsg('Please enter client name')
      } else if (!FormData.city) {
        setShowCreateLinkAlert(true)
        setCreateLinkMsg('Please select city')
      } else if (!FormData.area) {
        setShowCreateLinkAlert(true)
        setCreateLinkMsg('Please select area')
      } else {
        let myClientLink = await createClientLink(FormData)
        console.log('myClientLink', myClientLink)
        if (myClientLink.data.id) {
          setShowCreateLinkAlert(true)
          setCreateLinkMsg('Link created successfully')
          setCreatedLink(myClientLink.data.id)
        }
      }
    }
  }

  const handleCopyLink = () => {
    if (countSelected > 0 && createdLink) {
      navigator.clipboard.writeText(`allyspaces.com/selectWorkspaces/${createdLink}`)
    } else if (countSelected === 0 || createdLink === '' || createdLink === undefined) {
      setShowCreateLinkAlert(true)
      setCreateLinkMsg('Please create link first')
    }
  }

  return (
    <>
      <Container fluid={true} className="border rounded">
        <Row className="justify-content-center">
          <h1>Url Gen tool</h1>
        </Row>
        <Row className="justify-content-center">
          <Col sm={12} lg={6}>
            <label htmlFor="clientName" className="mb10">
              Enter Client Name
            </label>
            <input
              type="text"
              name="clientName"
              id="clientName"
              placeholder="Client Name"
              // value={FormData['clientName']}
              style={{
                marginTop: '7px',
                padding: '0.3rem',
                width: '100%',
              }}
              value={FormData['clientName']}
              onChange={handleChange}
            />
          </Col>
          <Col sm={12} lg={6}>
            <div className="my-2">
              <RangeSlider priceRange={priceRange} onPriceRangeChange={handlePriceRangeChange} />
            </div>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-center">
          <Col sm={12} lg={6}>
            <Dropdown
              formLabel="Select City"
              // buttonText="Find Workspaces"
              onChange={(e) => handleSelectCity(e)}
            >
              {allCities.map((city, itr) => {
                return itr === 0 ? <Option value={city} /> : <Option value={city} />
              })}
            </Dropdown>
          </Col>
          <Col sm={12} lg={6}>
            <label>Select Area</label>
            <br />
            <FormControl className={classes.formControl}>
              <InputLabel id="mutiple-select-label">Select Area</InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange2}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : '',
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={selected.length > 0 && selected.length < areaArray.length}
                    />
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                </MenuItem>
                {areaArray.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={selected.indexOf(option) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outline-secondary"
              className="mb-2 ml-4"
              onClick={() => {
                clearAll()
              }}
            >
              Clear All
            </Button>
          </Col>
          <Col></Col>
        </Row>
        <Row className="justify-content-center border-bottom">
          <p>
            You selected {cityValue} and{' '}
            {selected.length === 0 || selected.length == areaArray.length
              ? 'All Areas'
              : selected.join(', ')}{' '}
            and monthly price range Rs ({priceRange[0]} - {priceRange[1]})
          </p>
        </Row>
        <ModalWide
          props={{
            show: showModal,
            onHide: () => handleModal(),
          }}
          title="Space Availability"
          body={
            modalType === 'IMAGES' ? (
              <ImageViewing modalData={modalData} msg={succMsg} deleteMsg={deleteMsg} />
            ) : modalType === 'EDITWORKSPACE' ? (
              <WorkspaceHandling modalData={modalData} msg={succMsg} />
            ) : (
              <AddWorkSpace msg={succMsg} />
            )
          }
        />
        <Row className="justify-content-between m-1 p-1 mt-3">
          <Col lg={8}>
            <p className="mt-2 font-weight-bold">
              Total Selected Workspaces - {countSelected}, max 5
            </p>
            <Button
              variant="outline-primary"
              className="mb-2"
              onClick={() => {
                clearSelections()
              }}
            >
              Clear Selections
            </Button>
            <p>
              {/* {allSelected.map((items) => {
                items
              })} */}
            </p>
            <Row className="justify-content-center">
              <div className="mb-5"></div>
              <div className="container">
                <div className="d-flex flex-row flex-wrap justify-content-between bd-highlight">
                  {arrayIdsNames.map((space, inx) => {
                    return (
                      <div key={inx} className="bg-light m-1 p-2">
                        {space.name}
                      </div>
                    )
                  })}
                </div>
              </div>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="row row-cols-lg-1">
              {showCreateLinkAlert ? (
                <Alert variant="info" onClose={() => setShowCreateLinkAlert(false)} dismissible>
                  <Alert.Heading>Alert!</Alert.Heading>
                  <p>{createLinkMsg}.</p>
                </Alert>
              ) : (
                ''
              )}

              <Button variant="outline-primary" className="mb-2" onClick={handleCreateLink}>
                Create Link
              </Button>
            </div>
            <div className="row row-cols-lg-1">
              <Button variant="outline-primary" className="mb-2" onClick={handleCopyLink}>
                Copy Link
              </Button>
            </div>
          </Col>
        </Row>
        <Row
          className="justify-content-center pt-10"
          style={{
            marginTop: '15px',
          }}
        >
          <p>Choose from the workspaces below matching your criteria</p>
        </Row>
        <Row className="justify-content-between">
          {/* <Col className="">
            <input
              type="search"
              name="query"
              placeholder="Search"
              className="m-1 p-1"
              onChange={handleSearch}
            />
            <i className="fas fa-search m-1"></i>
          </Col> */}
          {/* {status.toBeDeletedWorkspace && status.toBeDeletedWorkspace.length > 0 ? (
            <Col className="d-flex m-1">
              <Button variant="outline-secondary">
                Delete {status.toBeDeletedWorkspace.length} WorkSpaces
              </Button>
            </Col>
          ) : null} */}
        </Row>
        {status.spaceDeleteMsg && status.spaceDeleteMsg.includes('Workspaces deleted.') ? (
          <p className="text-success text-center mt-3">{status.spaceDeleteMsg}</p>
        ) : status.spaceDeleteMsg && status.spaceDeleteMsg !== '' ? (
          <p className="text-danger text-center mt-3">{status.spaceDeleteMsg}</p>
        ) : null}
        <Row lg={1}>
          {filteredWorkspaces?.length > 0 &&
            filteredWorkspaces?.map((data, inx) => {
              let {
                space_code,
                name,
                address: { address, area, city, state },
                price,
                _id,
                // description: {rating, accessibility, market_access, offer },
                // timings: {working_hours},
                // amenities= [],
                // space_details,
                // cluster,
                // market_category
              } = data
              return (
                <div
                  key={name + inx}
                  style={
                    arrayIdsNames.some((array) => array.id === _id)
                      ? highlightWorkspae
                      : showWorkspace
                  }
                >
                  <RemarksCard
                    styleCard="mb-1 pb-1"
                    main={
                      <Row className="m-1 p-1 tempClass">
                        <Col lg={8} sm={8} className="h6">
                          <label className="font-weight-bold">Name: </label> {name}
                          <br />
                          <label className="font-weight-bold">Space Code: </label>
                          {space_code}
                          <br />
                          <label className="font-weight-bold">Address: </label>
                          {address}
                          <br />
                          <label className="font-weight-bold ">Location:</label>
                          {area + ', ' + city + ', ' + state}
                          <br />
                          <label className="font-weight-bold mt-2">Open Desk Price:</label>
                          {price ? `Rs ${price.open_desk} per month` : 'Not available'}
                          <br />
                        </Col>
                        <Col lg={4} sm={4}>
                          <Row lg={1}>
                            <Button
                              variant="outline-primary"
                              className="mb-2"
                              onClick={() => handleModal(data, 'IMAGES')}
                            >
                              View Images
                            </Button>
                            <Button
                              variant="outline-primary"
                              className="mb-2"
                              active={allSelected[inx]}
                              onClick={() => {
                                let found = false
                                arrayIdsNames.forEach((item, index) => {
                                  if (item.id === _id) {
                                    found = true
                                    let copyArr = [...arrayIdsNames]
                                    copyArr[index].id = 1
                                    copyArr[index].name = ''
                                    setArrayIdsNames(copyArr)
                                    let data = { ...FormData }
                                    data['selections'] = arrayIdsNames
                                    setFormData(data)
                                    handleSelectWorkspace(data, inx)
                                    setCountSelected(countSelected - 1)
                                  }
                                })
                                if (found === false && countSelected < 5) {
                                  let copyArr = [...arrayIdsNames]
                                  copyArr[countSelected].id = _id
                                  copyArr[countSelected].name = name
                                  setArrayIdsNames(copyArr)
                                  let data = { ...FormData }
                                  data['selections'] = arrayIdsNames
                                  setFormData(data)
                                  handleSelectWorkspace(data, inx)
                                  setCountSelected(countSelected + 1)
                                }
                                console.log('arrayIdsNames', arrayIdsNames)
                              }}
                            >
                              Select WorkSpace{' '}
                              {arrayIdsNames.some((array) => array.id === _id) ? (
                                <i className="fas fa-check"></i>
                              ) : null}
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    }
                  />
                </div>
              )
            })}
        </Row>
      </Container>
    </>
  )
}

export default DynamicUrls
