import { BASE_URL } from '../config'

import axios from 'axios'
import Cookie from 'js-cookie'

const fetch = axios.create({
  method: 'post',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'content-type': 'multipart/formdata',
    Authorization: 'Bearer ' + Cookie.get('AAToken'),
  },
  baseURL: BASE_URL,
})

export const formatFormDataRequest = (data) => {
  let formdata = new FormData()
  for (let i in data) {
    if (typeof data[i] === 'object' && i !== 'file') {
      formdata.append(i, JSON.stringify(data[i]))
    } else {
      formdata.append(i, data[i])
    }
  }
  return formdata
}

export const LoginUser = async (req = {}) => {
  let formData = formatFormDataRequest(req)

  let res = await fetch.post('/user/login', formData)

  if (res && res.status === 200) {
    let {
      data: { data, message },
    } = res

    if (data && data.token) {
      let { token } = data
      Cookie.set('AAToken', token, { expires: 3 })
      return data
    }
    return { message }
  }
  return false
}

export const createVendor = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/vendor/addVendor', data)
  if (res && res.status === 200) {
    let { data } = res.data
    return { ...data }
  } else {
    return 'Network error!'
  }
}

export const createWorkSpace = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/workspace/addWorkSpace', data)
  if (res && res.status === 200) {
    let { data } = res.data
    return { ...data }
  } else {
    return 'Network error!'
  }
}

export const suggestLocation = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/common/suggestLocation', data)
  if (res && res.status === 200) {
    let { data = [] } = res.data
    return data
  } else {
    return 'Network error!'
  }
}

export const getVendors = async (query) => {
  let data = formatFormDataRequest(query)
  let res = await fetch.post('/vendor/getVendor', data)
  if (res && res.status === 200) {
    let { data = [] } = res.data
    return data
  } else {
    return 'Network error!'
  }
}

export const getSpaces = async (formData) => {
  let data = formData?.query
  // console.log(data, 'data', formData, 'formData')
  if (data) {
    let res = await fetch.post(`/workspace/getWorkSpaces?q=${data}`)
    // console.log(res, 'res')
    if (res && res.data.status === 200) {
      let data = res.data.data
      return data
    } else {
      return 'Network error!'
    }
  } else {
    let res = await fetch.post('/workspace/getWorkSpaces')
    if (res && res.data.status === 200) {
      let data = res.data.data
      // console.log(data, 'data')
      return data
    } else {
      return 'Network error!'
    }
  }
}

export const getAreasByCities = async (data) => {
  let res = await fetch.get(`/workspace/areasByCity?city=${data}`)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const getAllAmenities = async () => {
  let res = await fetch.get('/workspace/getAllAmenities')
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const addCustomer = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/customer/addCustomer', data)
  if (res && res.status === 200) {
    let { data = [] } = res.data
    return data
  } else {
    return 'Network error!'
  }
}

export const fetchCustomer = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/customer/fetchCustomers', data)
  if (res && res.status === 200) {
    let { data = [] } = res.data
    return data
  } else {
    return 'Network error!'
  }
}

export const updateCustomer = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/customer/updateCustomer', data)
  if (res && res.status === 200) {
    let data = res.data
    return data
  } else {
    return 'Network error!'
  }
}

export const uploadSpaceImages = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/workspace/uploadImages', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}
export const updateImagePriority = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/workspace/updateImagePriority', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const deleteSpaceImages = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/workspace/deleteImages', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}
export const editWorkspaceDetails = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/workspace/editWorkspace', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}
export const getLeads = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/customer/getLeads', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const getContactedLead = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/user/getContactedLead', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const getUsers = async (formData) => {
  let res = await fetch.post('/user/getUsers')
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const createUser = async (formData) => {
  console.log('This is form data ', formData)
  let data = formatFormDataRequest(formData)
  console.log('data', data)

  let res = await fetch.post('/user/signup', data)
  console.log('This is res', res)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const createClientLink = async (FormData) => {
  let data = formatFormDataRequest(FormData)
  console.log('data', data)
  //return data;
  let res = await fetch.post('/client/createLink', data)
  console.log('This is res', res)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const profile = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/user/profile', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const addAmenty = async (formData) => {
  console.log('This is form data before format', formData)
  let data = formatFormDataRequest(formData)
  console.log('This is form data after format', data)
  let res = await fetch.post('/workspace/addAmenty', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return { message: 'Network error!' }
  }
}

export const deleteWorkspace = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/workspace/deleteWorkspace', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const deleteAmenity = async (formData) => {
  console.log('This is form data before format', formData)
  let data = formatFormDataRequest(formData)
  console.log('This is form data after format', data)
  let res = await fetch.post('/workspace/deleteAmenity', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const uploadBlogs = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/blogs/addBlog', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const updateBlog = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/blogs/updateBlog', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const getAllBlogs = async () => {
  let res = await fetch.get('/blogs/getBlogs')
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const getBlogDetails = async (data) => {
  let formData = formatFormDataRequest(data)
  let res = await fetch.post('/blogs/getBlogById', formData)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const deleteBlog = async (id) => {
  let res = await fetch.delete(`/blogs/deleteBlog?id=${id}`)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const getTieups = async () => {
  let res = await fetch.get('/tieups/getTieups')
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

export const getTieupDetails = async (data) => {
  let formData = formatFormDataRequest(data)
  let res = await fetch.post(`/tieups/getTieupById/`, formData)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}

// upload Tieups
export const uploadTieups = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/tieups/addTieup', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Error !' + res.status
  }
}

export const updateTieup = async (formData) => {
  let data = formatFormDataRequest(formData)
  let res = await fetch.post('/tieups/updateTieup', data)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Error !' + res.status
  }
}

export const deleteTieups = async (id) => {
  let res = await fetch.delete(`/tieups/deleteTieup?id=${id}`)
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Error !' + res.status
  }
}

export const getAllEnquiry = async () => {
  let res = await fetch.get('/publicRoute/getEnquiry')
  if (res && res.status === 200) {
    let { data } = res
    return data
  } else {
    return 'Network error!'
  }
}
