import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'

const TableMain = ({ header = [], fieldData = [[]], settings = [], handleClick, from = '' }) => {
  return (
    <Table striped bordered>
      <thead>
        <tr>
          {header.map((data, inx) => {
            return <th key={data}>{data}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {fieldData.map((data, key) => {
          return (
            <tr key={'data' + key}>
              {data.map((defination, inx) => {
                return (
                  <td key={defination + inx}>
                    {from === 'vendor' && inx === 1 ? <i className="fas fa-star"></i> : ''}
                    {defination}
                  </td>
                )
              })}
              <td>
                <Row>
                  {settings.map((data, key) => {
                    return (
                      <Col lg={2}>
                        <a onClick={handleClick} className="customSettings text-dark pointer">
                          <i className={data}></i>
                        </a>
                      </Col>
                    )
                  })}
                </Row>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default TableMain
