import { createVendor, createWorkSpace } from '../../helper/api'

import React, { useState } from 'react'
// import {
//     useHistory
// } from "react-router-dom"
import { Button, Col, Container, Form, Nav, Row } from 'react-bootstrap'

import { createSpace } from '../../components/form/formElements'
import FormType from '../../components/form/newForm'

const STEPS = ['Vendor Details', 'Workspace Details', 'Space Types', 'Description']

const StepForm = () => {
  // let router = useHistory();
  const [status, setStatus] = useState({
    step: 0,
    errMsg: '',
    inputError: '',
    errField: [],
    subForm: 0,
  })
  const [formData, setFormData] = useState({
    space_details: [{}],
  })

  const handleTabClick = async (e, step) => {
    e.preventDefault()
    if (step >= 0 && step <= 4) {
      let newStatus = { ...status }
      if (step === 1 && !formData['vendor']) {
        let vendorData = await createVendor(formData)

        if (vendorData.message && vendorData.error && vendorData.error.code === 11000) {
          newStatus.errMsg = 'Vendor Exist!'
        } else if (vendorData.message !== 'Successful') {
          newStatus.errMsg = vendorData.message
        } else {
          let formValues = { ...formData }
          formValues['vendor'] = vendorData._id
          setFormData({ ...formValues })
          newStatus.step = step
          newStatus.errMsg = ''
        }
      } else if (step === 4) {
        handleSubmit()
      } else {
        newStatus.step = step
        newStatus.errMsg = ''
      }
      setStatus({ ...newStatus })
    }
  }

  const handleChange = (e, componentVal) => {
    let { name, value, type, required } = e.target
    if (required && !value) {
      let newStatus = { ...status }
      newStatus.errField.push(name)
      newStatus.inputError = '*Required field'
      setStatus({ ...newStatus })
    } else {
      if (type === 'checkbox') {
        let formValues = { ...formData }
        if (!formValues[name]) {
          formValues[name] = [value]
          setFormData({ ...formValues })
        } else {
          formValues[name].push(value)
          setFormData({ ...formValues })
        }
      } else if (componentVal) {
        let formValues = { ...formData }
        for (let field in componentVal) {
          formValues[field] = componentVal[field]
        }
        setFormData({ ...formValues })
      } else {
        let formValues = { ...formData }
        if (step === 2) {
          // console.log(status.subForm);
          // if(!formValues.space_details[status.subForm]){
          //     let val={};
          //     val[name] = value;
          //     formValues.space_details.push({...val});
          // }else {
          formValues.space_details[status.subForm][name] = value
          // }
        } else {
          formValues[name] = value
        }
        setFormData({ ...formValues })
      }
    }
  }

  const addForm = (e) => {
    e.preventDefault()
    let newStatus = { ...status }
    newStatus.subForm += 1
    setStatus({ ...newStatus })

    let formVal = { ...formData }
    formVal.space_details.push({})
    setFormData({ ...formVal })
  }

  const handleSubmit = async () => {
    let creationStatus = await createWorkSpace(formData)
    if (creationStatus) {
      let { message } = creationStatus
      let newStatus = { ...status }
      newStatus.errMsg = message
      setStatus({ ...newStatus })
    }
  }

  let { step, errMsg, inputError, errField } = status

  return (
    <Col className="pt-5 mt-5 justify-content-center">
      <Container className="w-50">
        <Nav fill variant="tabs">
          {STEPS.map((data, inx) => {
            return (
              <Nav.Item key={inx + data}>
                <Nav.Link eventKey={data} className={step === inx ? 'active' : ''} disabled={true}>
                  {data}
                </Nav.Link>
              </Nav.Item>
            )
          })}
        </Nav>
        <Form className="m-1 p-1">
          <Row lg={2}>
            {status.step === 2 &&
              status.subForm > 0 &&
              formData.space_details
                .filter((data, inx) => inx < formData.space_details.length - 1)
                .map((spaceTypeData, inx) => {
                  return (
                    <>
                      {createSpace[STEPS[2]].map((data, inx) => {
                        let { label, name, type, placeholder, required, options = [] } = data

                        return (
                          <div key={name + inx}>
                            <FormType
                              key={name + inx}
                              label={label}
                              name={name}
                              type={type}
                              value={spaceTypeData[name]}
                              placeholder={placeholder}
                              options={options}
                              required={required}
                              styleGroup="p-1"
                              styleInput={
                                errField.includes(name) && inputError ? 'border-danger' : ''
                              }
                              handleChange={handleChange}
                            />
                            {errField.includes(name) && inputError ? (
                              <label className="text-danger">{inputError}</label>
                            ) : (
                              ''
                            )}
                          </div>
                        )
                      })}
                    </>
                  )
                })}

            {createSpace[STEPS[step]].map((data, inx) => {
              let { label, name, type, placeholder, required, options = [] } = data

              if (placeholder === 'Username') {
                placeholder = 'Email'
              }

              return (
                <div key={name}>
                  <FormType
                    key={name}
                    label={label}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    options={options}
                    required={required}
                    styleGroup="p-1"
                    styleInput={errField.includes(name) && inputError ? 'border-danger' : ''}
                    handleChange={handleChange}
                  />
                  {errField.includes(name) && inputError ? (
                    <label className="text-danger">{inputError}</label>
                  ) : (
                    ''
                  )}
                </div>
              )
            })}
          </Row>
          <Row className="justify-content-center">
            {step === 2 ? (
              <Button variant="outline-secondary" onClick={addForm}>
                Add
              </Button>
            ) : (
              ''
            )}
            {errMsg ? <label className="text-danger">{errMsg}</label> : ''}
          </Row>
          <Row className={step === 0 ? 'justify-content-end' : 'justify-content-between'}>
            {step > 0 ? (
              <Button className="" onClick={(e) => handleTabClick(e, step - 1)}>
                Previous
              </Button>
            ) : (
              ''
            )}
            <Button onClick={(e) => handleTabClick(e, step + 1)}>
              {step === 3 ? 'Submit' : 'Next'}
            </Button>
          </Row>
        </Form>
      </Container>
    </Col>
  )
}

export default StepForm
