import {
  createWorkSpace,
  deleteSpaceImages,
  deleteWorkspace,
  editWorkspaceDetails,
  getSpaces,
  updateImagePriority,
  uploadSpaceImages,
} from '../../../helper/api'

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

// import InfoCard from "../../../components/cards/infoCard";
import RemarksCard from '../../../components/cards/RemarksCard'
import ModalWide from '../../../components/modal/wideModal'

import AddWorkSpace from './workSpaceManager/addWorkspace'
import ImageHandling from './workSpaceManager/imageHandling'
import WorkspaceHandling from './workSpaceManager/workSpaceHandling'

const VALID_TYPES = ['image/png', 'image/jpeg', 'image/jpg']
const hideWorkspace = { opacity: 0.4 }
const showWorkspace = { opacity: 1 }

function refreshPage() {
  window.location.reload(false)
}
// workspace starts here
const Workspace = () => {
  const [spaces, setSpaces] = useState([])
  const [allSpaces, setAllSpaces] = useState([])

  const [status, setStatus] = useState({
    showModal: false,
    modalData: {},
    modalType: '',
    uploadedFiles: [],
    deletedFiles: 0,
    succMsg: '',
    deleteMsg: '',
    spaceDeleteMsg: '',
    toBeDeletedWorkspace: [],
  })

  const handleModal = (data, type) => {
    let newStatus = { ...status }
    newStatus.showModal = !newStatus.showModal
    if (type === 'IMAGES' || type === 'EDITWORKSPACE' || type === 'ADDWORKSPACE') {
      newStatus.modalType = type
    } else {
      newStatus.modalType = ''
    }

    if (newStatus.showModal === true && type != 'ADDWORKSPACE') {
      newStatus.modalData = { ...data }
    } else {
      newStatus.modalData = {}
      newStatus.uploadedFiles = []
      newStatus.succMsg = ''
      newStatus.deletedFiles = 0
      newStatus.deleteMsg = ''
    }

    setStatus({ ...newStatus })
  }

  const getWorkSpaces = async (query) => {
    let space = await getSpaces(query)
    if (space) {
      setAllSpaces(space)
      setSpaces(space)
    }
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    let { value } = e.target
    const newSpaces = allSpaces.filter(
      (x) =>
        x.name.toLowerCase().includes(value.toLowerCase()) ||
        x.space_code.toLowerCase().includes(value.toLowerCase())
    )
    setSpaces(newSpaces)

    //await getWorkSpaces({ query: value })
  }

  const handleUpload = async (e) => {
    let { files = [] } = e.target

    let newStatus = { ...status }
    for (let file = 0; file < files.length; file++) {
      if (VALID_TYPES.indexOf(files[file].type) > -1) {
        let params = {
          file: files[file],
          id: status.modalData._id,
          source: 'workspaces',
        }

        let res = await uploadSpaceImages(params)
        console.log(res)
        if (res.message === 'Failed') {
          console.log(res.error || res.message, 'Error : ')
          newStatus.succMsg = 'Error : ' + res.error
        }
        // Error : undefined
        if (res.url) {
          newStatus.uploadedFiles.push(res.url)
        }
      }
    }
    if (newStatus.uploadedFiles.length > 0) {
      newStatus.succMsg = 'Uploaded ' + newStatus.uploadedFiles.length + ' Images'
    }
    console.log(newStatus.succMsg)
    setStatus({ ...newStatus })
  }

  const handleImageDelete = async (data) => {
    let newStatus = { ...status }
    let params = {
      space_code: data.space_code,
      img: JSON.stringify(data.img),
    }
    let res = await deleteSpaceImages(params)
    if (res.message === 'Image deleted successfully.') {
      newStatus.deleteMsg = 'Image deleted successfully.'
      newStatus.deletedFiles = data.img.length
    } else {
      newStatus.deleteMsg = 'Error : ' + res.error || res.message
    }
    setStatus({ ...newStatus })
  }

  const handleImagePriority = async (data) => {
    let newStatus = { ...status }
    let params = {
      space_code: data.space_code,
      img: JSON.stringify(data.img),
    }
    let res = await updateImagePriority(params)
    if (res.message === 'Image priority updated successfully.') {
      newStatus.succMsg = 'Image priority updated successfully.'
    } else {
      newStatus.succMsg = 'Error : ' + res.error || res.message
    }
    setStatus({ ...newStatus })
  }

  const addToDeleteWorkspace = (data) => {
    let newstatus = { ...status }
    let targetIndex = newstatus.toBeDeletedWorkspace.indexOf(data._id)
    if (targetIndex >= 0) {
      newstatus.toBeDeletedWorkspace.splice(targetIndex, 1)
    } else {
      newstatus.toBeDeletedWorkspace.push(data._id)
    }
    setStatus({ ...newstatus })
  }

  const deleteWorkspaces = async () => {
    let newStatus = { ...status }
    let imageUrls, targetSpace
    let deletedSpaces = []
    for (let i = 0; i < newStatus.toBeDeletedWorkspace.length; i++) {
      targetSpace = spaces.filter((x) => x._id === newStatus.toBeDeletedWorkspace[i])
      imageUrls = targetSpace.img
      handleImageDelete(imageUrls, newStatus)
      let res = await deleteWorkspace({
        id: newStatus.toBeDeletedWorkspace[i],
      })
      if (res.data.message.includes(newStatus.toBeDeletedWorkspace[i])) {
        deletedSpaces.push(newStatus.toBeDeletedWorkspace[i])
      }
    }
    if (deletedSpaces.length > 0)
      newStatus.spaceDeleteMsg = deletedSpaces.length + ' Workspaces deleted.'
    else newStatus.spaceDeleteMsg = 'Some issue in deleting workspaces'
    setStatus({ ...newStatus })
  }

  const handleEdit = async (newDetails) => {
    console.log('This are my new details', newDetails)
    let newStatus = { ...status }
    let params = {
      name: newDetails.name,
      space_code: newDetails.space_code,
      id: status.modalData._id,
      address: { ...newDetails.address },
      price: { ...newDetails.price },
      description: { description: newDetails.description },
      amenities: newDetails.amenities,
      pricings: newDetails.pricings,
      weekly_hours: newDetails.weekly_hours,
      spaceTypes: newDetails.spaceTypes,
    }
    let res = await editWorkspaceDetails(params)
    console.log('RESULT', res)
    if (res.message === 'Failed') {
      // console.log(res.err || res.message, 'Error : ' + res.error)
      newStatus.succMsg = 'Error : ' + res.error
    }

    if (res.message === 'Space Details Updated.') {
      newStatus.succMsg = res.message
      refreshPage()
    }
    if (res.message === 'New workspace created.') {
      refreshPage()
    }
    setStatus({ ...newStatus })
    getWorkSpaces()
  }

  const handleAdd = async (spaceDetails) => {
    let newStatus = { ...status }
    // console.log('This is papa making requ')
    console.log(spaceDetails)
    let res = await createWorkSpace(spaceDetails)
    if (res.message) {
      newStatus.succMsg = res.message
    } else {
      newStatus.succMsg = 'Some issues while creating new space'
    }
    setStatus({ ...newStatus })
    if (res.message === 'New workspace created.') {
      refreshPage()
    }
  }

  useEffect(() => {
    getWorkSpaces()
  }, [])

  let { showModal, modalData, modalType, succMsg, deleteMsg } = status

  return (
    <Container fluid={true}>
      <ModalWide
        props={{
          show: showModal,
          onHide: () => handleModal(),
        }}
        title="Space Availability"
        body={
          // <SpaceAvailability
          // 	modalType={modalType}
          // 	modalData={modalData}
          // 	handleUpload={handleUpload}
          // 	msg={succMsg}
          // />
          modalType === 'IMAGES' ? (
            <div className="">
              <div style={{ color: 'red' }} className="">
                Upload only 1200 x 756 px images
              </div>
              <ImageHandling
                modalData={modalData}
                handleImagePriority={handleImagePriority}
                handleUpload={handleUpload}
                msg={succMsg}
                handleImageDelete={handleImageDelete}
                deleteMsg={deleteMsg}
              />
            </div>
          ) : modalType === 'EDITWORKSPACE' ? (
            <WorkspaceHandling modalData={modalData} handleEdit={handleEdit} msg={succMsg} />
          ) : (
            <AddWorkSpace handleAdd={handleAdd} msg={succMsg} />
          )
        }
      />
      <Row>
        <h1>Workspaces</h1>
      </Row>
      {/* search */}
      <Row className="justify-content-between">
        <Col className="">
          <input
            type="search"
            name="query"
            placeholder="Search"
            className="m-1 p-1"
            onChange={handleSearch}
          />
          <i className="fas fa-search m-1"></i>
        </Col>

        {status.toBeDeletedWorkspace && status.toBeDeletedWorkspace.length > 0 ? (
          <Col className="d-flex m-1">
            <Button variant="outline-secondary" onClick={() => deleteWorkspaces()}>
              Delete {status.toBeDeletedWorkspace.length} WorkSpaces
            </Button>
          </Col>
        ) : null}

        <Col className="d-flex flex-row-reverse m-1">
          <Button variant="outline-secondary" onClick={() => handleModal({}, 'ADDWORKSPACE')}>
            Add WorkSpace
          </Button>
        </Col>
      </Row>
      {status.spaceDeleteMsg && status.spaceDeleteMsg.includes('Workspaces deleted.') ? (
        <p className="text-success text-center mt-3">{status.spaceDeleteMsg}</p>
      ) : status.spaceDeleteMsg && status.spaceDeleteMsg !== '' ? (
        <p className="text-danger text-center mt-3">{status.spaceDeleteMsg}</p>
      ) : null}
      <Row lg={1}>
        {/* lg={4} sm={1} */}
        {spaces.length > 0 &&
          spaces
            .sort((a, b) => {
              return a.priority > b.priority ? 1 : -1
            })
            .map((data, inx) => {
              // let {
              //   name,
              //   space_code,
              //   address: { address, area, city, state },
              //   price: price,
              //   // description: {rating, accessibility, market_access, offer },
              //   // timings: {working_hours},
              //   // amenities= [],
              //   // space_details,
              //   // cluster,
              //   // market_category
              // } = data
              let {
                name,
                space_code,
                address: { address, area, city, state },
                price,
                _id,
                // description: {rating, accessibility, market_access, offer },
                // timings: {working_hours},
                // amenities= [],
                // space_details,
                // cluster,
                // market_category
              } = data
              return (
                <RemarksCard
                  key={name + inx}
                  styleCard="mb-1 pb-1"
                  main={
                    <Row className="m-1 p-1">
                      <Col
                        className="h6"
                        style={
                          status.toBeDeletedWorkspace.includes(data._id)
                            ? hideWorkspace
                            : showWorkspace
                        }
                      >
                        <label className="font-weight-bold">Space Code: </label>
                        {space_code}
                        <br />
                        <label className="font-weight-bold">Name: </label> {name}
                        <br />
                        <label className="font-weight-bold">Address: </label>
                        {address}
                        <br />
                        <label className="font-weight-bold mt-2"> Area: </label>
                        {area} <br />
                        <label className="font-weight-bold mt-2"> City: </label>
                        {city} <br />
                        <label className="font-weight-bold mt-2"> State: </label>
                        {state}
                        <br />
                        <label className="font-weight-bold mt-2">Open Desk Price:</label>
                        {price ? `Rs ${price.open_desk} ` : 'Not available'}
                        <br />
                        {/* <label> */}
                      </Col>
                      <Col>
                        <Row lg={1}>
                          <Button
                            variant="outline-secondary"
                            className="mb-2"
                            onClick={() => handleModal(data, 'IMAGES')}
                          >
                            View Images
                          </Button>
                          <Button
                            variant="outline-secondary"
                            className="mb-2"
                            onClick={() => handleModal(data, 'EDITWORKSPACE')}
                          >
                            Edit WorkSpace
                          </Button>
                          <Button
                            variant="outline-secondary"
                            onClick={() => addToDeleteWorkspace(data)}
                          >
                            {status.toBeDeletedWorkspace.includes(data._id)
                              ? "Don't Delete"
                              : 'Delete WorkSpace'}
                          </Button>
                          {/* <Button className="mt-1 pt-1" variant="outline-secondary" onClick={()=>handleModal(space_details)}>Availability</Button> */}
                        </Row>
                      </Col>
                    </Row>
                  }
                />
              )
            })}
      </Row>
    </Container>
  )
}

export default Workspace
