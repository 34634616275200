import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'

import { createSpace, spaceProfile } from '../../../../components/form/formElements'
import Form from '../../../../components/form/newForm'

const hideImage = { opacity: 0.4 }
const showImage = { opacity: 1 }

const ImageHandling = ({
  modalData,
  handleUpload,
  msg,
  handleImageDelete,
  deleteMsg,
  handleImagePriority,
}) => {
  const [imageDeleteList, setImageDeleteList] = useState([])
  const handleDelete = async (data) => {
    let newImages = [...data.img]
    imageDeleteList.forEach((item) => {
      newImages = newImages.filter((image) => image !== item)
    })
    data.img = newImages
    handleImageDelete(data)
    setImageDeleteList([])
  }
  const handleAddToDeleteList = (data) => {
    let newImageDeleteList = [...imageDeleteList]
    if (imageDeleteList.includes(data)) {
      newImageDeleteList = newImageDeleteList.filter((item) => item !== data)
    } else {
      newImageDeleteList.push(data)
    }
    setImageDeleteList(newImageDeleteList)
  }

  return (
    <>
      <Row>
        <Col>
          {spaceProfile.map((data, inx) => {
            let { label, name, type } = data
            return (
              <input
                key={name + inx}
                label={label}
                name={name}
                type={type}
                multiple={true}
                onChange={handleUpload}
                title="Only jpg, jpeg, png files are allowed."
              />
            )
          })}
          {msg ? <div className="text-success text-center">{msg}</div> : ''}
        </Col>
      </Row>
      <hr />
      <Row xs={1} lg={3} className="m-1 p-1">
        {console.log('ImageHandling: ', modalData.img)}
        {modalData && modalData.img
          ? modalData.img
              .sort((a, b) => a.priority - b.priority)
              .map((data, inx) => {
                {
                  // console.log('ImageHandling: ', data)
                }
                return (
                  <Col className="" key={data + inx}>
                    <div className="m-1 ">
                      {/* {JSON.stringify(data.priority)} */}
                      <Image
                        src={data.link}
                        className="p-2 w-100 h-100"
                        style={imageDeleteList.includes(data) ? hideImage : showImage}
                        rounded
                      />
                      {/* <div className="">{JSON.stringify(data)}</div> */}
                      <div className="d-flex ml-2 mb-2 ">
                        <input
                          type="number"
                          className="w-50"
                          defaultValue={data.priority}
                          onChange={(e) => {
                            let newPriority = e.target.value
                            let newImages = [...modalData.img]
                            newImages[inx].priority = newPriority
                            modalData.img = newImages
                            // if (newPriority === data.priority) return
                            handleImagePriority(modalData)
                            console.log(modalData, 'modalData', newPriority, 'newPriority')
                          }}
                        />
                        <div
                          className="text-center ml-3"
                          onClick={() => {
                            handleAddToDeleteList(data)
                          }}
                        >
                          {imageDeleteList.includes(data) ? (
                            <i className="fas fa-undo"></i>
                          ) : (
                            <i className="fas fa-trash-alt"></i>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })
          : null}
      </Row>
      <Row className="mt-4 text-center">
        {imageDeleteList && imageDeleteList.length > 0 ? (
          <Button
            className="custom-outline-primary ml-2"
            onClick={() => {
              handleDelete(modalData)
            }}
          >{`Delete ${imageDeleteList.length} Images`}</Button>
        ) : null}
        {deleteMsg ? <div className="text-success text-center ml-4">{deleteMsg}</div> : ''}
        {msg ? <div className="text-success text-center">{msg}</div> : ''}
      </Row>
    </>
  )
}

export default ImageHandling
