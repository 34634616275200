const formElement = {
  username: {
    label: 'Email',
    name: 'email',
    type: 'email',
    placeholder: 'Username',
    required: true,
    controlId: 'formBasicEmail',
  },
  password: {
    label: 'Password',
    name: 'password',
    type: 'password',
    placeholder: 'Password',
    required: true,
    controlId: 'formBasicPassword',
  },

  // Basic Contact info
  name: {
    label: 'Name',
    name: 'name',
    type: 'text',
    placeholder: 'Name',
    required: true,
  },
  designation: {
    label: 'Designation',
    name: 'designation',
    type: 'text',
    placeholder: 'Designation',
    required: true,
  },
  contact: {
    label: 'Mobile',
    name: 'mobile',
    type: 'number',
    placeholder: 'Mobile Number',
    required: true,
  },
  request_description: {
    label: 'Description',
    name: 'description',
    type: 'textarea',
    placeholder: 'Description...',
    required: true,
  },
  source: {
    label: 'Source',
    name: 'source',
    type: 'text',
    placeholder: 'Ex: facebook, linkedin...',
    required: true,
  },
  // space form elements
  space_name: {
    label: 'Space Name',
    name: 'space_name',
    type: 'text',
    placeholder: 'Space Name',
    required: true,
  },
  space_code: {
    label: 'Space Code',
    name: 'space_code',
    type: 'text',
    placeholder: 'Alt Space Name',
    required: true,
  },
  space_address: {
    label: 'Address',
    name: 'address',
    type: 'text',
    placeholder: 'Space address',
    required: true,
  },
  space_location: {
    label: 'Location',
    name: 'location',
    type: 'autosuggest',
    placeholder: 'Ex: Bengaluru...',
    required: true,
  },
  space_state: {
    label: 'State',
    name: 'state',
    type: 'text',
    placeholder: 'State',
    required: true,
  },
  space_city: {
    label: 'City',
    name: 'city',
    type: 'text',
    placeholder: 'City',
    required: true,
  },
  space_area: {
    label: 'Area',
    name: 'area',
    type: 'text',
    placeholder: 'Area',
    required: true,
  },

  // price form elements
  space_price_daypass: {
    label: 'Day Pass',
    name: 'day_pass',
    type: 'text',
    placeholder: 'Price of Daily Pass',
  },
  space_price_opendesk: {
    label: 'Open Desk',
    name: 'open_desk',
    type: 'text',
    placeholder: 'Price of Open Desk for a month',
  },

  space_landmark: {
    label: 'Landmark',
    name: 'landmark',
    type: 'text',
    placeholder: 'Landmark',
    required: true,
  },
  space_description: {
    label: 'Description',
    name: 'description',
    type: 'textarea',
    placeholder: 'Space Description',
    required: true,
  },
  space_rating: {
    label: 'Rating',
    name: 'rating',
    type: 'select',
    placeholder: 'Rating',
    options: ['select', 'Bad', 'Better', 'Average', 'Best', 'Excellent'],
  },
  space_accessibility: {
    label: 'E-Commerce Accessibility',
    name: 'accessibility',
    type: 'select',
    placeholder: 'Accessibility',
    options: ['select', 'Bad', 'Better', 'Average', 'Best', 'Excellent'],
  },
  space_market_access: {
    label: 'Market Access',
    name: 'market_access',
    type: 'select',
    placeholder: 'Market access',
    options: ['select', 'Bad', 'Better', 'Average', 'Best', 'Excellent'],
  },
  space_offer: {
    label: 'Offer',
    name: 'offer',
    type: 'number',
    placeholder: 'Offer',
  },
  space_images: { label: 'Workspace Pictures', name: 'file', type: 'file' },

  space_type: {
    label: 'Space Type',
    name: 'spaceType',
    type: 'select',
    placeholder: 'Space Type',
    options: ['select', 'Office', 'Cabin'],
  },
  desk_count: {
    label: 'Desk Count',
    name: 'deskCount',
    type: 'number',
    placeholder: 'Desk Count',
  },
  cabin_count: {
    label: 'Cabin Count',
    name: 'cabinCount',
    type: 'number',
    placeholder: 'Cabin Count',
  },
  price: {
    label: 'Price',
    name: 'price',
    type: 'number',
    placeholder: 'Price',
  },
  per: {
    label: 'Pricing Frequency',
    name: 'per',
    type: 'select',
    placeholder: 'Pricing Frequency',
    options: ['select', 'Hourly', 'Day', 'Monthly', 'Quarterly', 'Yearly'],
  },
  availability: {
    label: 'Availability',
    name: 'availability',
    type: 'select',
    placeholder: 'Availability',
    options: ['Available', 'Not Available'],
  },

  space_timing_from: { label: 'Opening Time', name: 'from', type: 'time' },
  space_timing_to: { label: 'Closing Time', name: 'to', type: 'time' },
  space_working_days: {
    label: 'Working Days',
    name: 'working_days',
    type: 'number',
    placeholder: 'Working days',
  },
  space_amenities: {
    label: 'Amenities',
    name: 'amenities',
    type: 'checkbox',
    placeholder: 'Amenities',
    options: [
      'Parking',
      'Food Service',
      'Email',
      'Wifi',
      'Washrooms',
      'Cafeteria',
      'Library',
      'Private Lockers',
      'Events',
    ],
  },
  space_cluster: {
    label: 'Cluster',
    name: 'cluster',
    type: 'text',
    placeholder: 'Cluster',
  },
  space_notice_period: {
    label: 'Notice period',
    name: 'notice_period',
    type: 'text',
    placeholder: 'Notice Period',
  },
  space_remarks: {
    label: 'Remarks',
    name: 'remarks',
    type: 'text',
    placeholder: 'Remarks',
  },
  space_category: {
    label: 'Category',
    name: 'market_category',
    type: 'select',
    placeholder: 'Market category',
    options: ['Basic', 'Premium'],
  },

  date: {
    label: 'Date',
    type: 'date',
    name: 'date',
    placeholder: 'dd-mm-yyyy',
    value: new Date(),
    min: '1997-01-01',
    max: '2030-12-31',
  },
  remark: { label: 'Remark', type: 'text', name: 'remark' },
  customer_status: {
    label: 'Status:',
    type: 'select',
    name: 'status',
    options: ['REQUESTED', 'IN-TOUCH', 'ACTIVE', 'SUCCESS', 'FAILED'],
    tip: 'Update Customer Activity Status',
  },

  vendor_status: {
    label: 'Status:',
    type: 'select',
    name: 'status',
    options: ['IN-ACTIVE', 'ACTIVE'],
  },

  // Dashboard filters
  filter_from: {
    label: 'From',
    type: 'date',
    name: 'from',
    format: 'dd-MM-yyyy',
  },
  filter_to: { label: 'To', type: 'date', name: 'to', format: 'dd-MM-yyyy' },

  //User Creation
  role: {
    label: 'Role',
    name: 'role',
    type: 'select',
    options: ['ADMIN', 'USER'],
  },
  feature_list: {
    label: 'Features Access',
    name: 'feature_list',
    type: 'checkbox',
    placeholder: 'Features',
    options: ['Customer', 'Vendor', 'Working Spaces'],
  },

  //Amenties attributes
  amenty_name: {
    label: 'Amenty Name',
    name: 'amenty_name',
    type: 'text',
    placeholder: 'Amenty Name',
    required: true,
  },
  amenty_image: {
    label: 'Amenty Image',
    name: 'amenty_image',
    type: 'file',
    required: true,
  },
}

export const loginFields = [formElement.username, formElement.password]

export const customerForm = [
  formElement.name,
  formElement.designation,
  formElement.username,
  formElement.contact,
  formElement.source,
  formElement.request_description,
]

export const createSpace = {
  'Vendor Details': [
    formElement.name,
    formElement.designation,
    formElement.contact,
    formElement.username,
  ],
  'Workspace Details': [
    formElement.space_name,
    formElement.space_address,
    formElement.space_location,
    // formElement.space_state,
    formElement.space_description,
    formElement.space_rating,
    formElement.space_accessibility,
    formElement.space_market_access,
  ],
  Description: [
    formElement.space_timing_from,
    formElement.space_timing_to,
    formElement.space_working_days,
    formElement.space_amenities,
    formElement.space_cluster,
    formElement.space_notice_period,
    formElement.space_remarks,
    formElement.space_category,
    formElement.space_offer,
  ],
  'Space Types': [
    formElement.space_type,
    formElement.desk_count,
    formElement.cabin_count,
    formElement.price,
    formElement.per,
    formElement.availability,
  ],
}

export const customerProfileStatus = {
  Remark: [formElement.date, formElement.remark],
  status: [formElement.customer_status],
}

export const vendorProfileStatus = [formElement.vendor_status]

export const spaceProfile = [formElement.space_images]

export const dashboardFilter = [formElement.filter_from, formElement.filter_to]

export const userProfile = [
  formElement.name,
  formElement.username,
  formElement.password,
  formElement.role,
  formElement.feature_list,
]
export const spaceDetails = [
  formElement.space_name,
  formElement.space_code,
  formElement.space_address,
  formElement.space_state,
  formElement.space_city,
  formElement.space_area,
  formElement.space_description,
  formElement.space_price_daypass,
  formElement.space_price_opendesk,
]

export const amentyDetails = [formElement.amenty_name]
export const amentyImageDetails = [formElement.amenty_image]
