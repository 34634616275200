import {
  fetchCustomer,
  getContactedLead,
  getLeads,
  getSpaces,
  getVendors,
} from '../../../helper/api'
import { formatDate } from '../../../helper/util'

import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'

import Card from '../../../components/cards'
import { dashboardFilter } from '../../../components/form/formElements'
import Form from '../../../components/form/newForm'
import Table from '../../../components/table'

const Analytics = () => {
  const [data, setData] = useState({})
  const [formData, setFormdata] = useState({})

  const fetchCounts = async () => {
    let vendorCount = await getVendors()
    let customerCount = await fetchCustomer()
    let spaceCount = await getSpaces()
    let leadData = await getLeads()
    let userData = await getContactedLead()

    let leadFormattedData = []
    let userFormattedData = []

    if (leadData && leadData.data.length > 0) {
      for (let i = 0; i < leadData.data.length; i++) {
        if (leadData.data[i]['_id'] && leadData.data[i]['count']) {
          leadFormattedData.push([leadData.data[i]['_id'], leadData.data[i]['count']])
        }
      }
    }

    if (userData && userData.data.length > 0) {
      for (let i = 0; i < userData.data.length; i++) {
        if (userData.data[i]['name']) {
          userFormattedData.push([userData.data[i]['name'], userData.data[i]['customer']])
        }
      }
    }
    setData({
      vendors: vendorCount.vendors ? vendorCount.vendors.length : 0,
      customer: customerCount.length ? customerCount.length : 0,
      space: spaceCount.workSpaces ? spaceCount.workSpaces.length : 0,
      lead: leadFormattedData,
      user: userFormattedData,
    })
  }

  const cardData = [
    {
      header: 'Customer',
      title: 'Request',
      tip: 'Total customers.',
      body: data.customer,
      icon: 'fas fa-users fa-3x',
    },
    {
      header: 'Vendor',
      title: 'Total',
      tip: 'Total Vendors.',
      body: data.vendors,
      icon: 'fas fa-user-friends fa-3x',
    },
    {
      header: 'Workspace',
      title: 'Total',
      tip: 'Total Workspaces',
      body: data.space,
      icon: 'fas fa-store fa-3x',
    },
  ]

  const handleDateChange = async (e) => {
    let { name, value } = e.target
    let formValues = { ...formData }
    if (value) {
      formValues[name] = formatDate(value)
    }
    setFormdata({ ...formValues })
    if (formValues['from'] && formValues['to']) {
      let leadData = await getLeads(formValues)
      let leadFormattedData = []
      if (leadData && leadData.data.length > 0) {
        for (let i = 0; i < leadData.data.length; i++) {
          if (leadData.data[i]['_id'] && leadData.data[i]['count']) {
            leadFormattedData.push([leadData.data[i]['_id'], leadData.data[i]['count']])
          }
        }
      }
      let dataVal = { ...data }
      dataVal['lead'] = leadFormattedData
      setData({ ...dataVal })
    }
  }

  useEffect(() => {
    fetchCounts()
  }, [])
  return (
    <>
      <Row>
        <Col>
          <h1>Welcome to the Dashboard</h1>
        </Col>
      </Row>
      {/* <Row>
            {
                cardData.map((data, inx)=>{
                    let {
                        header,
                        title,
                        tip,
                        body,
                        icon
                    } = data;
                    return(
                        <Col key={header+inx}>
                            <Card 
                                key={header+inx}
                                header={header}  
                                title={title}
                                tip={tip}
                                body={body}
                                icon={icon}
                            />
                        </Col>
                    )
                })
            }
        </Row>
        <hr />
        <Row xs={1} md ={2}>
            {
                dashboardFilter.map((data, inx)=>{
                    let {
                        label,
                        name,
                        type,
                        placeholder,
                        min,
                        max,
                        format
                    } = data;
                    
                    return(
                        <Col key={name+inx}  lg={3}>
                            <Form
                                key={name+inx} 
                                label={label}
                                name={name}
                                type={type}
                                placeholder={placeholder}
                                min={min}
                                max={max}
                                format={format}
                                handleChange={handleDateChange}
                            />
                        </Col>
                    )
                })
            }
        </Row>
        <Row>
            <Col>
                <h2 className="text-center">Lead Tracking</h2>
                <Table 
                    header={["Lead", "Count"]}
                    fieldData={data.lead}
                />
            </Col>
            <Col>
                <h2 className="text-center">User Tracking</h2>
                <Table
                    header={["Employee", "Added Customers"]}
                    fieldData={data.user}
                />
            </Col>
        </Row> */}
    </>
  )
}

export default Analytics
