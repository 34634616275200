import { profile } from '../../helper/api'
import { Logout, useProvideAuth } from '../../helper/auth'

import React, { useEffect, useState } from 'react'
import { Dropdown, Image, Nav, Navbar } from 'react-bootstrap'

import ALLY_LOGO from '../../assets/logo192.png'

const Layout = () => {
  const [userName, setUserName] = useState('')
  let auth = useProvideAuth()

  const handleLogout = (e) => {
    e.preventDefault()
    Logout()
    window.location.reload()
  }

  const getProfile = async () => {
    let user = await profile()
    setUserName(user?.data?.userData?.name)
  }

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Navbar.Brand href="/" className="brand-name">
        <Image src={ALLY_LOGO} alt="Ally Spaces Logo" className="brand-img" />
        Ally Spaces
      </Navbar.Brand>
      <Navbar.Toggle
        data-toggle="collapse"
        data-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <Dropdown>
            {auth && auth.user && auth.user.user ? (
              <Dropdown.Toggle variant="light" className="mr-5">
                <i className="fas fa-user"></i>
              </Dropdown.Toggle>
            ) : (
              ''
            )}
            <Dropdown.Menu>
              <Dropdown.Item>{userName}</Dropdown.Item>
              <hr />
              <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Layout
