export default function Database() {
  return (
    <iframe
      src="https://app.appsmith.com/app/allyworkspaces/workspaces-test-63e254236b8ca42af5ad8a06"
      width="100%"
      height="100%"
      allowfullscreen="true"
      style={{
        border: 'none',
        height: '90vh',
        width: '100%',
        overflow: 'hidden',
      }}
    ></iframe>
  )
}
