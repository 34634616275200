import { getAllEnquiry } from '../../../helper/api'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'

import React, { useEffect, useState } from 'react'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    marginLeft: '250px',
  },
})

function Enquiry() {
  const classes = useStyles()
  const [enquiries, setEnquiries] = useState([])
  const [dataInCSV, setDataInCSV] = useState('')

  const formatCsvString = (data) => {
    if (data === undefined || data === null || data === '' || data == '') {
      return 'Not Provided'
    } else {
      let newData = data.replace(/(\r\n|\n|\r)/gm, '')
      newData = newData.includes(',') ? `"${newData}"` : newData
      newData = newData.replace(/,/g, '')
      return newData
    }
  }

  // prepare csv data
  const prepareDataForCsv = (response) => {
    let data = response.data
    // get data
    var CsvData = data
      .map((item) => {
        let source = formatCsvString(item.source)
        let status = formatCsvString(item.status)
        let createdAt = formatCsvString(item.createdAt)
        let name = formatCsvString(item.name)
        let email = formatCsvString(item.email)
        let company = formatCsvString(item.company)
        let mobile = formatCsvString(item.mobile)
        let description = formatCsvString(item.description ?? item.subject)

        const str = Object.values([
          source,
          status,
          name,
          email,
          mobile,
          company,
          description,
          createdAt,
        ]).join(',')

        item = str

        return item
      })
      .join('\n')
    // get headers
    let keys = Object.values([
      'Source',
      'Status',
      'Name',
      'Email',
      'Mobile',
      'Company',
      'Description',
      'Requested At',
    ]).join(',')
    CsvData = keys + '\n' + CsvData
    // pass data to variable
    setDataInCSV(CsvData)
  }

  useEffect(() => {
    async function fetchData() {
      const data = await getAllEnquiry()
      setEnquiries(data)
      prepareDataForCsv(data)
    }
    fetchData()
  }, [])

  return (
    <TableContainer component={Paper}>
      {dataInCSV && (
        <div className={classes.table}>
          <a
            href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
            className="btn btn-primary mb-4"
            download="enquiries.csv"
          >
            Export Data
          </a>
        </div>
      )}
      <Table className={classes.table} aria-label="enquiry table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Mobile</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enquiries?.data?.map((enquiry, id) => (
            <TableRow key={enquiry._id}>
              <TableCell>{enquiry.createdAt}</TableCell>
              <TableCell>{enquiry.name}</TableCell>
              <TableCell>{enquiry.email}</TableCell>
              <TableCell>{enquiry.mobile}</TableCell>
              <TableCell>{enquiry.company}</TableCell>
              <TableCell>{enquiry.source}</TableCell>
              <TableCell>{enquiry.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Enquiry
