import React, { createContext, useContext, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'

import Cookies from 'js-cookie'

const verifyAuth = () => {
  let authToken = Cookies.get('AAToken')
  if (authToken) {
    return { user: authToken }
  }
  return null
}

const Auth = {
  isAuthenticated: false,
  signin(cb) {
    Auth.isAuthenticated = true
    setTimeout(cb, 100) // fake async
  },
  signout(cb) {
    Auth.isAuthenticated = false
    setTimeout(cb, 100)
  },
}

const authContext = createContext()

export const useProvideAuth = () => {
  const [user, setUser] = useState(verifyAuth)

  const signin = (cb) => {
    return Auth.signin(() => {
      cb()
    })
  }

  const signout = (cb) => {
    return Auth.signout(() => {
      setUser(null)
      cb()
    })
  }

  return {
    setUser,
    signin,
    signout,
    user,
  }
}

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

export const PrivateRoute = ({ children, ...rest }) => {
  let auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export const PublicRoute = ({ children, ...rest }) => {
  let auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard/analytics',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export const Logout = () => {
  Cookies.remove('AAToken')
}
