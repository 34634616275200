import React from 'react'
import { Card } from 'react-bootstrap'

const RemarksCard = ({ main, footer, styleCard = '' }) => {
  return (
    <Card className={styleCard}>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <div>{main}</div>
          {footer ? <footer className="blockquote-footer text-right">{footer}</footer> : ''}
        </blockquote>
      </Card.Body>
    </Card>
  )
}

export default RemarksCard
