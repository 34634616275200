import { createUser, getUsers } from '../../../helper/api'

import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

import { userProfile } from '../../../components/form/formElements'
import Form from '../../../components/form/newForm'
import ModalBox from '../../../components/modal'
import WideModal from '../../../components/modal/wideModal'
import Table from '../../../components/table'

const Profile = () => {
  const [formData, setFormdata] = useState({})
  const [status, setStatus] = useState({
    showModal: false,
    usersList: [],
  })

  const handleModal = () => {
    let newStatus = { ...status }
    newStatus.showModal = !newStatus.showModal
    setStatus({ ...newStatus })
  }

  const handleChange = (e) => {
    let { name, value, type, checked } = e.target
    let formValues = { ...formData }
    if (name && value) {
      if (type !== 'checkbox') {
        formValues[name] = value
        if (name === 'role' && value === 'ADMIN') {
          formValues['feature_list'] = []
        }
      } else {
        if (checked) {
          if (formValues[name] && formValues[name].length > 0) {
            formValues[name].push(value)
          } else {
            formValues[name] = [value]
          }
        } else {
          formValues[name].splice(formValues[name].indexOf(value), 1)
        }
      }
    }
    setFormdata({ ...formValues })
    console.log(checked)
    console.log(formValues)
  }

  const handleSubmit = async () => {
    if (formData && formData['name'] && formData['email'] && formData['role']) {
      if (formData['role'] !== 'Select') {
        let data = await createUser(formData)
        console.log(data)
      } else {
        console.log('Please select a role')
      }
    }
  }

  const intializeProfiles = async () => {
    let users = await getUsers()
    let newStatus = { ...status }
    newStatus['usersList'] = users.data
    setStatus({ ...newStatus })
  }

  // const handleSetting = settingName => {
  // 	if (settingName === 'EDIT') {
  // 	}
  // }

  useEffect(() => {
    intializeProfiles()
  }, [])

  let { showModal, usersList } = status
  return (
    <Container>
      <ModalBox
        title="Add User"
        show={showModal}
        body={userProfile.map((data, inx) => {
          let { label, type, name, options } = data
          if (name === 'role' && options.indexOf('Select') !== 0) {
            options.unshift('Select')
          }
          if (name !== 'feature_list') {
            return (
              <Form
                key={name + inx}
                label={label}
                name={name}
                value={formData[name]}
                type={type}
                options={options}
                handleChange={handleChange}
              />
            )
          } else if (name === 'feature_list' && formData['role'] !== 'ADMIN') {
            return (
              <Form
                key={name + inx}
                label={label}
                name={name}
                type={type}
                options={options}
                handleChange={handleChange}
              />
            )
          }
        })}
        handleClose={handleModal}
        handleSubmit={handleSubmit}
      />
      <WideModal
        title="Access Management"
        props={{
          show: false,
        }}
        body={
          <Row>
            <Col>
              <h3>Change Password</h3>
              <input type="password" />
              <Button className="ml-2">Update</Button>
            </Col>
            <Col>
              <h3>Access List</h3>
            </Col>
          </Row>
        }
      />
      <Row className="justify-content-between">
        <Col>
          <h1>Profile</h1>
        </Col>
        <Col className="text-right">
          <Button variant="primary" className="mt-1" onClick={handleModal}>
            Add User
          </Button>
        </Col>
      </Row>
      <Row>
        <Table
          header={['Name', 'Email', 'Role', 'Manage']}
          fieldData={usersList.map((data) => {
            let { name, email, role } = data
            return [name, email, role]
          })}
          settings={['fab fa-keycdn']}
          handleClick={handleModal}
        />
      </Row>
    </Container>
  )
}

export default Profile
