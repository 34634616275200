import { deleteTieups, getTieups, updateTieup } from '../../../helper/api'

import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { Link, useHistory } from 'react-router-dom'

import moment from 'moment'

export const tieupsColumns = [
  {
    name: 'Title',
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: 'Image',
    selector: (row) => row.cardImage,
    sortable: true,
  },
  {
    name: 'Short Description',
    selector: (row) => row.shortDescription,
  },
  {
    name: 'Long Description',
    selector: (row) => row.longDescription,
  },
  {
    name: 'Address',
    selector: (row) => row.address,
  },
  {
    name: 'Contact',
    selector: (row) => row.contact,
  },
  {
    name: 'Website',
    selector: (row) => row.website,
  },
  {
    name: 'Location',
    selector: (row) => row.location,
  },
  {
    name: 'Action',
    selector: (row) => row.action,
  },
]

function TieupsTable() {
  const [allData, setAllData] = useState([])
  const [loading, setLoading] = useState(false)

  const getTieupsListing = async () => {
    let res = await getTieups()
    setAllData(res?.data)
    console.log(res?.data)
    setLoading(false)
  }

  const removeTieup = async (id) => {
    let res = await deleteTieups(id)
    if (res.status == 200) {
      getTieupsListing()
    }
  }
  const createTable = (tieupsData) => {
    return tieupsData?.map((obj, { index }) => {
      return {
        id: obj?._id,
        title: obj?.title,
        cardImage: <img src={obj?.card_image} width="100" height="50" />,
        shortDescription: obj?.short_description,
        longDescription: obj?.long_description,
        address: obj?.address,
        contact: obj?.contact,
        website: obj?.website_url,
        location: obj?.location,
        sortable: true,
        minWidth: '200px',
        action: (
          <div>
            <Link to={`tieup/${obj?._id}`}>
              <em className="fa fa-edit mr-4"></em>
            </Link>
            <Button variant="danger" onClick={() => removeTieup(obj?._id)}>
              <em className="fa fa-trash"></em>
            </Button>
          </div>
        ),
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    getTieupsListing()
  }, [])

  return (
    <div className="container-fluid">
      <div className="row">
        <Col className=" m-1">
          <Link to="tieup/add" className="btn btn-primary" style={{ marginLeft: '18rem' }}>
            Add Tieups
          </Link>
          <DataTable
            title="Tieups"
            columns={tieupsColumns}
            data={createTable(allData)}
            progressPending={loading}
            progressComponent={<div>Loading...</div>}
            persistTableHead
          />
        </Col>
      </div>
    </div>
  )
}

export default TieupsTable
