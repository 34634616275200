import { deleteBlog, getAllBlogs } from '../../../helper/api'

import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { Link, useHistory } from 'react-router-dom'

import moment from 'moment'

export const blogColumns = [
  {
    name: 'Title',
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: 'Banner',
    selector: (row) => row.banner,
    sortable: true,
  },
  {
    name: 'Signature By',
    selector: (row) => row.signature,
  },
  {
    name: 'Created At',
    selector: (row) => row.createdAt,
  },
  {
    name: 'Action',
    selector: (row) => row.action,
  },
]

function BlogTable() {
  const [allData, setAllData] = useState([])
  const [loading, setLoading] = useState(false)

  const getBlogsListing = async () => {
    let res = await getAllBlogs()
    setAllData(res?.data)
    setLoading(false)
  }

  const removeBlog = async (id) => {
    let res = await deleteBlog(id)
    if (res.status == 200) {
      getBlogsListing()
    }
  }
  const createTable = (blogsData) => {
    return blogsData?.map((obj, { index }) => {
      return {
        id: obj?._id,
        title: obj?.title,
        banner: <img src={obj?.banner} width="100" height="50" />,
        signature: obj?.signature,
        createdAt:
          obj?.createdDate ?? obj?.createdDate
            ? moment(obj?.createdDate ?? obj.createdAt).format('DD-MMM-yyyy')
            : '',
        sortable: true,
        minWidth: '100px',
        action: (
          <div key={index} className="d-flex">
            <a href="#" className="icon-view cursor-pointer">
              <Link to={`blog/${obj._id}`}>
                <em className="fa fa-edit mr-4"></em>
              </Link>
            </a>
            <a href="#" className="icon-view cursor-pointer">
              <em className="fa fa-trash" onClick={() => removeBlog(obj._id)}></em>
            </a>
          </div>
        ),
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    getBlogsListing()
  }, [])

  return (
    <div className="content mt-3">
      <Col className="d-flex flex-row-reverse m-1">
        <Link to={`blog/add`}>
          <Button variant="outline-secondary">Add Blog</Button>
        </Link>
      </Col>
      <DataTable
        columns={blogColumns}
        data={createTable(allData)}
        progressPending={loading}
        persistTableHead
      />
    </div>
  )
}

export default BlogTable
